.contact-form {
  display: flex;
  flex-direction: column;

  @include media-breakpoint-up(md) {
    flex-direction: row;
    gap: $gap-medium;
  }

  @include media-breakpoint-only(lg) {
    gap: 3rem;
  }

  @include media-breakpoint-up(xl) {
    gap: $gap-xlarge;
  }

  &__heading {
    margin-bottom: $gap-large;
  }

  &__text-container {
    width: 100%;

    @include media-breakpoint-only(md) {
      width: 45%;
    }

    @include media-breakpoint-up(lg) {
      width: 50%;
    }
  }

  &__controls-container {
    width: 100%;

    @include media-breakpoint-only(md) {
      width: 55%;
    }
    
    @include media-breakpoint-up(lg) {
      width: 50%;
    }
  }

  &__button-container {
    margin-top: $gap-large;

    .btn {
      font-size: $text-3;
      line-height: $line-height-t3;
      width: 11rem;
    }
  }

  .form-element {
    margin-bottom: $gap-small;
  }

  .form-element__input-container {
    margin-right: 0;
  }
}