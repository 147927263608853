﻿.search-page {

    .heading {
        @include font-primary-regular();
    }

    .text {
        color: #000000;
        margin: 0;
    }

    .form-element__spinner {
        margin-top: 32px;
    }

    @include media-breakpoint-up(lg) {
        #search-container {
            margin: 0 70px;
        }

        .text--t2 {
            padding-right: $gap-large;
        }
    }

    @include media-breakpoint-down(sm) {
        .searchImage {
            display: none;
        }
    }
}
.type {
    color: $black;
}

.searchImage {
    display: block;
    object-fit: contain;
    width: 100%;
}

.search-item-vertical {
    padding: 2rem;
}

.unified-search-results {
    &-link-container {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    &-ingress {
        padding: $gap-large $gap-large 0 0;
    }
}

.searchDate {
    display: block;
    color: #002ea2;
}

#filtercolapse {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
#filter {
    background-color: white;
    color: #002da1;
}

.filter-border {
    border: 1px solid #002da1;
}


.search-filters .form-element__checkbox label {
    font-size: 20px;
}


@include media-breakpoint-down(sm) {
    .search-filters .form-element__checkbox {
        margin-right: 20px;
        color: $black;
    }

    .search-result-text {
        line-height: 3rem;
    }

    .form-element__checkbox {
        width: 40%;
        padding-top: 10px;
    }

    #filtercolapse {
        width: 100%;
        padding: 12px 10px;
    }

    .searchDate {
        float: right;
    }

    .unified-search-results {
        .unified-search-item {
            &-link-container {
                flex-direction: column;
                align-items: start;
            }
        }
    }
}

