.form-element__checkbox-single-container {
  padding: 12px 20px;
  background-color: #f5f5f5;
  border: none;
  border-radius: 0;
  line-height: 22px;
  margin-right: 36px;
  min-height: 60px;

  .form-element__checkbox-container {
    padding: 6px 0;

    label {
      color: $primary;
      font-size: 16px;
    }
  }
}

input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.form-element__checkbox {
  position: relative;
  padding-left: 37px;
  padding-top: 1px;
  line-height: 24px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-box-decoration-break: clone; // to enable padding to be effective on multiline strings
  color: $primary;

  label {
    cursor: pointer;

    a {
      color: $electric-blue;
    }
  }

  strong {
    @include font-primary-bold();
  }
}

.form-element__checkbox-checkmark {
  border: 1px solid $primary;
  border-radius: 3px;
  width: 26px;
  height: 26px;
  box-sizing: border-box;
  display: block;
  background-color: $white;
  position: absolute;
  top: 0px;
  left: 0px;
}

.form-element__checkbox input:checked ~ .form-element__checkbox-checkmark {
  border-color: $primary;
  background-image: url(../../../img/form/checkbox-check-icon.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 22px;
  background-color: $primary;
}

.form-element__checkbox
input:focus ~ .form-element__checkbox-checkmark {
    @include focus-styles();
}
