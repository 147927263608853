.company-intro {
    height: 100%;
    display: flex;
    flex-direction: column;    
}

.company-intro__heading__description {
    min-height: 586px;
}

.company-intro__heading {
    font-size: 64px;
    line-height: 72px;
    border-bottom: 7px solid #002da1;
    padding-bottom: 13px;
    word-break: break-word;
}

.company-intro__description {
    p {
        font-size: 24px;
        line-height: 32px;
        color: #000;
    }
}

.company-intro__logo {
    max-width: 100%;
    height: 100px;
    display: inline-block;
    object-fit: contain;
}

.company-intro__button-wrapper {
    margin-top: $gap-medium;
}

.company-intro__logo-wrapper {
    padding-top: 30px;
    text-align: left;
}

@include media-breakpoint-down(lg) {
    .company-intro__button-wrapper {
        text-align: center;
    }

    .company-intro__heading__description {
        min-height: 0;
    }
}

@include media-breakpoint-up(lg) {
    .company-intro__logo-wrapper {
        bottom: 30px;
    }
}

@include media-breakpoint-down(md) {
    .company-intro__heading {
        font-size: 46px;
        line-height: 54px;
    }
}