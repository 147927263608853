﻿.section-main-page {
    .content {
        &__header {
            margin-top: 0;

            @include media-breakpoint-down(md) {
                margin-top: 1rem;
            }
        }

        &__fact-text-container {
            margin: 0;

            @include media-breakpoint-down(md) {
                margin: $gap-large-px 0;
            }
        }
    }

    .content__text {
        &:not(:has(+ .btn), :has(+ .highlights__buttons)) {
            margin-bottom: 0;
        }
    }
}
