.form-element__addon {
    .btn {
        margin-top: 16px;
    }
}

@include media-breakpoint-up(md) {
    .form-element__addon {
        width: 1%;
        white-space: nowrap;
        vertical-align: middle;
        position: relative;

        .btn {
            margin-left: 35px;
            margin-top: 0;
        }
    }
}