.form-description {
    margin-top: 31px;
    margin-bottom: 16px;

    p + p {
        margin-top: 22px;
    }
}

.form-description {
    margin-top: 31px;
}

@include media-breakpoint-up(md) {
    .form-description {
        margin-top: 39px;
    }
}