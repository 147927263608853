.image-links {
    &__heading {
        margin-bottom: $gap-medium;

        @include media-breakpoint-up(md) {
            margin-bottom: $gap-large;
        }
    }

    &__link {

        &:hover {
            text-decoration: none;
        }

        &:focus {
            outline: transparent;
        }

        &:hover, &:focus {
            .image-links__link-text {
                border-top: $border-primary;
                border-bottom: $border-primary;
            }
        }
        // Show the external link icon next to h3
        &.external-link {
            .image-links__link-heading {
                display: inline-flex;
            }
        }
    }

    &__image {
        @include media-breakpoint-down(md) {
            display: none;
        }

        display: block;
        width: 100%;
        object-fit: cover;
        aspect-ratio: 384/266;
    }

    &__link-heading {
        text-transform: none;
        margin: 0;
    }

    &__link-text {
        @include font-primary-bold();
        display: inline-block;
        padding-top: $gap-small;
        padding-bottom: $gap-small;
        margin-top: $gap-small;
        border-top: 2px solid transparent;
        border-bottom: 2px solid transparent;
    }
}
