.icon-links {
  &__heading {
    @include media-breakpoint-down(md) {
      margin: 0;
    }
  }

  &__link {
    margin-bottom: 2.5rem;

    &:hover {
      text-decoration: none;
    }

    &:focus {
      outline: transparent;
    }

    &:hover, &:focus {
      @include media-breakpoint-down(md) {
        .icon-links__link-text {
          border-top: $border-primary--medium;
          border-bottom: $border-primary--medium;
        }
      }

      @include media-breakpoint-up(md) {
        .icon-links__icon-container {
          background-color: $light-blue;
        }
  
        .icon-links__icon {
          fill: $primary;
            .cls-1 {
                fill: $primary;
            }            
            .cls-2 {
                stroke: $primary;
            }
        }
      }
    }

    // Show the external link icon next to h3
    &.external-link h3 {
        display: inline-flex;
    }
  }
  &__icon-container {
    display: none;

    @include media-breakpoint-up(md) {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      aspect-ratio: 1;
      background-color: $primary;
      transition: background-color 0.2s ease-in-out;

      @media (prefers-reduced-motion: reduce) {
        transition: none;
      }
    }
  }
  &__icon {
    fill: $white;
    width: 90px !important;
    height: 90px;
    transition: fill 0.2s ease-in-out;

    .cls-1 {
        fill: $white;
    }
        
    .cls-2 {
        stroke: $white;
    }

    @media (prefers-reduced-motion: reduce) {
      transition: none;
    }

    @include media-breakpoint-only(lg) {
      width: 140px;
      height: 140px;
    }

    @include media-breakpoint-only(xl) {
      width: 170px;
      height: 170px;
    }
  }

  &__link-heading {
    margin: $gap-large 0 0;
  }

  &__link-text {
    @include font-primary-bold();
    text-transform: none;
  }
}
