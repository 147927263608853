.services {
  &__text-container {
    display: flex;
    align-items: center;
    justify-content: center;

    @include media-breakpoint-up(md) {
      padding: 0;
    }

    &--inner {
      padding-bottom: $gap-medium-px;

      @include media-breakpoint-up(lg) {
        padding-bottom: $gap-large-px;
      }
    }
  }

  &__text {
    font-size: $text-4;
    line-height: $line-height-t4;
    color: $black;
    margin: 0;
  }

  &__heading {
    &--main {
      font-size: $heading-h3-mobile;
      line-height: $line-height-h3-mobile;
      margin: 0 0 $gap-large-px;

      @include media-breakpoint-up(lg) {
        font-size: $heading-h3;
        line-height: $line-height-h3;
      }
    }

    &--sub {
      font-size: $heading-h4-mobile;
      line-height: $line-height-h4-mobile;
      margin: 0 0 $gap-small-px;
      text-transform: none;

      @include media-breakpoint-up(lg) {
        font-size: $heading-h4;
        line-height: $line-height-h4;
        margin: 0 0 $gap-medium-px;
      }
    }
  }

  &__button {
    margin-top: $gap-medium-px;

    @include media-breakpoint-up(lg) {
      margin-top: 0;
    }
  }
}