.header__logo {
    position: relative;
    @include font-primary-regular();
    color: $primary;
    text-decoration: none;
    text-transform: uppercase;
    display: inline-block;
    padding: 0.375rem 0;
    font-size: $heading-h4-mobile;
    line-height: $heading-h4-mobile;
    border-top: $border-transparent--thin;
    border-bottom: $border-transparent--thin;

    &:hover {
        text-decoration: none;
    }

    &:focus {
        outline: transparent;
    }

    &:hover, &:focus {
        border-top: $border-primary--thin;
        border-bottom: $border-primary--thin;
    }

    strong {
        @include font-primary-bold();
    }
}

.fs-header__logo {
    @include font-primary-regular();
    font-size: 24px;
    line-height: 25.2px;
    color: $primary;
    text-decoration: none;
    text-transform: uppercase;
    display: inline-block;
    margin: 22px 0;

    &:hover {
        text-decoration: none;
    }

    strong {
        @include font-primary-bold();
    }
}

.header-login__logo {
    height: 32px;
}

@include media-breakpoint-up(lg) {
    .fs-header__logo {
        @include font-primary-regular();
        font-size: 32px;
        line-height: 34.6px;
        color: $primary;
        text-decoration: none;
        text-transform: uppercase;
        display: inline-block;
        margin: 31px 0;
    }
}
