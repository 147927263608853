.form-element__file-container {
  position: relative;
  display: block;
  margin-right: 36px;

  img,
  .video-file__frame {
    max-width: 200px;
    max-height: 100px;
  }
}

.form-element__file {
  display: none;
}

.form-element__file-delete {
  width: 22px;
  height: 27px;
  background: url(../../../img/form/delete-icon.svg) center center no-repeat;
  position: absolute;
  top: 16px;
  right: 20px;
  cursor: pointer;
  border: none;

  /*    &.form-element__file-delete-existing {
        top: 0;
        right: 0;
    }*/
}
