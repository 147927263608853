.footer {
    background-color: $dark-blue;
    color: $white;
}

.footer__inner {
    margin: $gap-xlarge 0;
}

.footer__logo {
    display: block;
    margin-bottom: $gap-large;
}

.footer__block {
    p {
        color: $white;
        font-size: $text-3;
        line-height: $line-height-t3;
        margin: 0;
    }

    &:nth-child(-n + 2) {
        margin-bottom: $gap-medium;
    }

    @include media-breakpoint-up(sm) {
        &:nth-child(-n + 2) {
            margin-bottom: $gap-large;
        }
    }

    @include media-breakpoint-up(lg) {
        &:nth-child(-n + 2) {
            margin-bottom: 0;
        }
    }
}

.footer__block-heading {
    @include font-primary-bold();
    font-size: $text-3;
    line-height: $line-height-t3;
    margin: 0 0 $gap-medium;
    color: $white;
    text-transform: none;
}

.footer__links {
    list-style-type: none;
}

.footer__links-link {
    color: $white;
    font-size: $text-3;
    line-height: $line-height-t3;    

    &--new-tab {
        display: flex;
        align-items: center;

        img {
            margin-left: $gap-small;
        }
    }
}

.footer__social-link {
    display: inline-block;
    margin-bottom: $gap-medium;

    &:nth-child(-n + 4) {
        margin-right: $gap-medium;
    }

    &.external-link {
        svg {
            display: none;
        }
    }
}

.market-opportunity-footer {
    &__newsletter-container {
        background-color: #e4dffc;
        padding-top: $gap-large;
        padding-bottom: $gap-large;

        &--row-container {
            row-gap: $gap-large;
        }

        &--image-container {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;

            &--image {
                height: 62px;
            }
        }

        &--button-container {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            // This is to limit the button width because otherwise it went to the full length of the display in tablet and mobile
            max-width: 400px;
        }
    }
}