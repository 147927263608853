﻿@import "design-tokens";

.indent-text-block {
    margin-top: $gap-large;
    margin-bottom: $gap-large;
    border-left: 4px solid #002da1;
    padding-left: $gap-medium;

    @include media-breakpoint-only(md) {
        margin-left: 3rem;
    }

    @include media-breakpoint-up(lg) {
        margin-left: $gap-xlarge;
        padding-left: $gap-large;
    }
}

.editor-content {
    :is(p, h1, h2, h3, h4):is(.blue-box),
    :is(ul, ol):has(li.blue-box) {
        --blue-box-margin-y: 4rem;
        --blue-box-box-padding-y: 4rem;
        --blue-box-content-padding-y: .7rem;
        --blue-box-padding-y: calc(var(--blue-box-box-padding-y) + var(--blue-box-content-padding-y));
        --blue-box-padding-x: 4rem;
        margin: var(--blue-box-margin-y) calc(-.5 * var(--bs-gutter-x));
        padding: var(--blue-box-padding-y) var(--blue-box-padding-x);
        background-color: $light-blue;
        color: $primary;

        @include media-breakpoint-down(sm) {
            --blue-box-content-padding-y: 1rem;
            --blue-box-padding-x: 0.75rem;
            margin-bottom: calc(var(--blue-box-box-padding-y) + var(--blue-box-margin-y));
            padding-bottom: var(--blue-box-content-padding-y);
            padding-top: var(--blue-box-content-padding-y);
            /*padding-bottom: var(--blue-box-content-padding-y);*/
        }
        // All items in the group except for the first one
        + :is(p, h1, h2, h3, h4):is(.blue-box),
        + :is(ul, ol):has(li.blue-box) {
            margin-top: calc(-1 * var(--blue-box-box-padding-y) - var(--blue-box-margin-y));
            padding-top: var(--blue-box-content-padding-y);

            @include media-breakpoint-down(sm) {
                margin-bottom: calc(var(--blue-box-box-padding-y) + var(--blue-box-margin-y));
                padding-bottom: var(--blue-box-content-padding-y);
            }
        }
    }

    :is(ul, ol):has(li.blue-box) {
        padding-left: 6.75rem;
        color: black;

        @include media-breakpoint-down(sm) {
            padding-left: 2.75rem;
        }

        ul, ol {
            --blue-box-margin-y: 0;
            --blue-box-box-padding-y: 0;
            margin-left: 2rem;
        }
    }
}

.image-caption {
    font-size: $text-3-mobile;
    line-height: $line-height-t3-mobile;
    margin-top: $gap-medium;


    @include media-breakpoint-up(lg) {
        font-size: $text-3;
        line-height: $line-height-t3;
        margin-top: $gap-large;
    }
}

.editor-content ul {
    @include font-primary-regular;
    color: $black;
    list-style-type: disc;
    margin-left: $gap-large;
    font-size: 20px;
}

.editor-content ol {
    @include font-primary-regular;
    color: $black;
    margin-left: $gap-large;
    font-size: 20px;
}

a.btn.btn--primary,
a.btn.btn--secondary {
    svg {
        display: none;
    }
}