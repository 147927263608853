﻿.content-filtering-block {
    .search-field__container {
        margin-bottom: 16px;

        input {
            color: $electric-blue;
        }
    }

    .form-element__checkbox-container {
        margin-bottom: 16px;
        display: inline-table;

        .form-element__checkbox {
            display: inline-block;
            margin-bottom: 16px;

            label {
                font-size: 20px;
                color: $electric-blue;
            }
        }
    }
}
@include media-breakpoint-up(md) {
    .content-filtering-block {
        .search-field__container {
            margin-bottom: 32px;
        }

        .search-field__container-inner {
            display: flex;
            flex-wrap: wrap;
            align-items: stretch;
            width: 100%;
        }

        .search-field__input {
            display: block;
            flex: 1 1 auto;
            width: 1%;
            min-width: 0;
        }

        .search-field__btn {
            margin: 0;
            white-space: nowrap;
            display: block;
            align-items: center;
            width: auto;
        }

        .form-element__checkbox-container {
            display: flex;
            justify-content: center;
            margin-bottom: 32px;

            .form-element__checkbox {
                width: auto;
                margin-right: 30px;
                margin-bottom: 0;
            }
        }
    }
}

@inlude media-breakpoint-down(md) {
    ::-webkit-input-placeholder {
        /* WebKit browsers */
        color: transparent;
    }
}

@include media-breakpoint-up(lg) {
    .content-filtering-block{
        .search-field__container {
        margin-bottom: 32px;
    }
        }

    .filtering-container {
        margin: 45px 0 32px;
    }
}
