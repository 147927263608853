form.marketingForm,
div[data-form-api-url*="dynamics.com"] {
    *,
    *:not(.inlineOverlay, .emptySectionPlaceholder, .designer-overlay__content)
    * {
        @include font-primary-regular();
    }

    font-size: $text-2-mobile !important;
    @include font-primary-regular();
    color: #000;

    button.submitButton {
        font-size: 18px !important;
        line-height: 30px !important;
        padding: 14px 32px 12px !important;
        background-color: $primary !important;
        color: $white !important;
        border: 2px solid $primary !important;
        text-align: center;
        text-transform: uppercase;
        cursor: pointer;
        display: inline-block;
        border-radius: 0 !important;
        transition: background-color 0.15s ease-in-out 0s, border-color 0.15s ease-in-out 0s, color 0.15s ease-in-out 0s;

        @media (prefers-reduced-motion: reduce) {
            transition: none;
        }

        &:hover {
            text-decoration: none !important;
            background-color: transparent !important;
            color: $primary !important;
        }
    }

    input[type="text"],
    input[type="number"],
    input[type="email"] {
        @include font-primary-regular();
        height: 60px;
        padding: 0 20px;
        width: 100%;
        outline: none;
        line-height: 60px;
        position: relative;
        -moz-appearance: textfield;
        font-size: $text-3-mobile !important;
        margin: 0;
    }

    input[type="text"],
    input[type="number"],
    input[type="email"],
    select,
    textarea {
        background-color: #f5f5f5 !important;
        border: none !important;
        color: $primary !important;
        font-size: $text-3-mobile !important;

        &:focus {
            @include focus-styles();
        }
    }

    input[type="checkbox"] {
        opacity: 1 !important;
        position: relative !important;
        width: 30px;
        height: 30px;
        border: 1px solid;
        border-color: $primary;
        border-radius: 3px;
        display: block;
        background-color: #fff;
        margin-right: 16px;
        line-height: 24px;
        -webkit-appearance: none;
        appearance: none;

        &:checked {
            border-color: $primary;
            background: url(../../../img/form/checkbox-check-icon.svg) center center no-repeat $primary;
            background-image: url(../../../img/form/checkbox-check-icon.svg);
            background-position: center;
            background-repeat: no-repeat;
            background-size: 22px;
            background-color: $primary;
        }
    }

    div:has(> select) {
        &:after {
            display: none !important;
        }
    }
    // Add label styles for form elements but not to data inside contentBlock
    // Mainly checkboxes / agreements at the end of the form before submit button.
    // For some reason the checkbox text is inside a label instead of something like span.
    label:not(.consentBlock *) {
        padding: 12px 10px 7px !important;
        font-size: 12px !important;
        text-transform: uppercase !important;
    }

    .consentBlock {
        // Change only the top and bottom padding but keep the left and right aligned accordind to rest of the form.
        padding: 10px 30px !important;
    }

    select {
        @include font-primary-regular();
        color: $primary;
        font-size: 18px;
        height: 60px !important;
        padding: 12px 20px !important;
        line-height: 36px;
        width: 100%;
        text-transform: uppercase;
        -webkit-font-smoothing: antialiased;
        appearance: none;
        padding-right: 70px;
        cursor: pointer;
        position: relative;
        margin: 0 !important;
        background-image: url(../../../img/form/select-icon.svg) !important;
        background-repeat: no-repeat !important;
        background-position-x: 98% !important;
        background-position-y: center !important;
    }

    .optionSetFormFieldBlock {
        background-image: none !important;
    }

    p {
        @include font-primary-regular();
        color: $primary !important;
        margin: 0 !important;
        font-size: $text-2-mobile !important;
        line-height: $line-height-t2-mobile !important;
    }

    label > p {
        margin-bottom: 10px !important;
    }

    h1,
    h2,
    h3,
    h4 {
        @include font-primary-bold();
        color: red !important;
        text-transform: uppercase !important;
        margin: 0 0 $gap-medium !important;

        @include media-breakpoint-up(lg) {
            margin: 0 0 $gap-large !important;
        }
    }

    h1 {
        font-size: $heading-h1-mobile !important;
        line-height: $line-height-h1-mobile !important;

        @include media-breakpoint-up(lg) {
            font-size: $heading-h1 !important;
            line-height: $line-height-h1 !important;
        }
    }

    h2 {
        font-size: $heading-h2-mobile !important;
        line-height: $line-height-h2-mobile !important;

        @include media-breakpoint-up(lg) {
            font-size: $heading-h2 !important;
            line-height: $line-height-h2 !important;
        }
    }

    h3 {
        font-size: $heading-h3-mobile !important;
        line-height: $line-height-h3-mobile !important;

        @include media-breakpoint-up(lg) {
            font-size: $heading-h3 !important;
            line-height: $line-height-h3 !important;
        }
    }

    h4 {
        font-size: $heading-h4-mobile !important;
        line-height: $line-height-h4-mobile !important;

        @include media-breakpoint-up(lg) {
            font-size: $heading-h4 !important;
            line-height: $line-height-h4 !important;
        }
    }


    table,
    div {
        max-width: 100%;
    }
}
