﻿.contact-us-page {
    &__contact-us-page-top-area {
        .row .block:last-child {
            margin-bottom: 0;
        }
    }

    &__contact-us-page-results-container {
        display: none;
        min-height: 18rem;

        &.show {
            display: block;
        }
        // Bootstrap spinner
        .spinner-border {
            display: none;
            margin: $gap-xlarge auto;
            width: 3rem;
            height: 3rem;

            &.show {
                display: block;
            }
        }
    }

    &__contact-us-page-results-title {
        color: $finland-blue;
        margin: $gap-large 0 2.5rem 0;
    }

    &__contact-us-page-results-noresults {
        font-weight: bold !important;
        color: $finland-blue;
        margin: $gap-large 0 2.5rem 0;
    }
}
