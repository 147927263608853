/*
How to use

<div class="form-element__radio-container">
  <input id="radio-1" type="radio" name="radio-selection" />
  <label class="form-element__radio-label" for="radio-1">
    Text
  </label>
</div> 
*/

.form-element__radio-container {
  display: flex;
  align-items: center;
  gap: $gap-small;
  margin: $gap-medium 0;
}

.form-element__radio-label {
  font-size: 1rem;
  color: $black;
}

.form-element__radio-container:focus-within {
  color: $primary;
}

input[type="radio"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
  appearance: none;
  /* For iOS < 15 */
  background-color: $white;
  /* Not removed via appearance */
  margin: 0;

  font: inherit;
  color: $primary;
  width: 2rem;
  height: 2rem;
  border: 2px solid $primary;
  border-radius: 50%;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}

input[type="radio"]::before {
  content: "";
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1rem 1rem $primary;
  /* Windows High Contrast Mode */
  background-color: CanvasText;
}

input[type="radio"]:checked::before {
  transform: scale(1);
}

input[type="radio"]:focus-visible {
  outline: 2px solid $primary;
  outline-offset: 2px;
}
