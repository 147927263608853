.content {
    &__fact {
        display: flex;
        flex-direction: column;
        margin-top: $gap-large-px;
        z-index: 1;

        @include media-breakpoint-up(lg) {
            margin-top: $gap-xlarge-px;
        }

        @include media-breakpoint-up(xl) {
            margin-top: $gap-xxxlarge-px;
        }

        &--services {
            margin-left: $gap-large-px;

            @include media-breakpoint-up(lg) {
                margin-left: $gap-xlarge-px;
            }

            @include media-breakpoint-up(xl) {
                margin-left: $gap-xxlarge-px;
            }
        }
    }

    &__fact-number {
        color: $white;
        font-size: $heading-hero;
        line-height: $line-height-hero;

        @include media-breakpoint-up(md) {
            font-size: 4.5rem;
            line-height: $line-height-hero;
        }
    }

    &__fact-text-container {
        display: flex;
        margin: $gap-large-px 0;
        flex-direction: column;
        flex-grow: 1;
        justify-content: space-between;

        @include media-breakpoint-up(lg) {
            margin: $gap-xlarge-px 0;
        }
        @media (max-width: 321px) {
            flex-grow: 0.5;
        }
    }

    &__fact-text, &__fact-text p, &__fact-text h2, &__fact-text h3, &__fact-text h4 {
        color: $white;
        font-size: $heading-h3;
        line-height: $line-height-h3;
        max-width: 302px;

        @include media-breakpoint-up(lg) {
            font-size: $heading-h1;
            line-height: $line-height-h1;
            max-width: 331px;
        }

        @include media-breakpoint-up(xl) {
            max-width: 385px;
        }

        &--small {
            font-size: $text-2;
            line-height: $line-height-t2;

            @include media-breakpoint-up(lg) {
                font-size: $text-1;
                line-height: $line-height-t1;
            }
        }

        &--services, &--services p {
            max-width: 302px;

            @include media-breakpoint-up(lg) {
                max-width: 331px;
            }

            @include media-breakpoint-up(xl) {
                max-width: 385px;
            }
        }

        strong {
            font-family: finlandica-bold,sans-serif;
        }
    }
}
