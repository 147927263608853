.hero {
  &__card {
    display: flex;
    width: 50%;
    height: 50%;
    position: relative;

    &:hover,
    &:focus {
      outline: none;

      .hero__card-text {
        border-bottom: 2px solid $primary;
      }
    }

    &.external-link svg {
      display: none;
    }
  }

  &__card-text {
    position: absolute;
    top: 1rem;
    left: 1rem;
    @include font-primary-bold();
    font-size: $text-3-mobile;
    line-height: $line-height-t3-mobile;
    color: $primary;

    @include media-breakpoint-up(md) {
      font-size: $heading-h4;
      line-height: $line-height-h4;
    }
  }

  &__card-image {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
