﻿$color-brand-black: #191919;

.data-center-map-block {

    .carousel-item img {
        height: 500px;
        object-fit: contain;
    }

    #profile {
        p, span {
            color: $color-brand-black;
            font-size: 1rem;
        }

        p {
            margin-bottom: 0;
        }

        .datacenter-accordion-button {
            background-color: #f5f5f5;
            color: $color-brand-black;
            cursor: pointer;
            border: none;
            width: 100%;
            text-align: left;
            text-transform: none;

            &::before {
                content: '';
                width: 20px;
                height: 20px;
                display: inline-block;
                margin-right: $gap-small;
                transform: scale(0.6);
                background: url(../../icons/plus.png) center center no-repeat;
                position: relative;
                top: 3px;
            }

            &[aria-expanded="true"] {
                &::before {
                    background-image: url(../../icons/minus.png);
                }
            }

            &:focus {
                outline: 2px solid $primary;
            }
        }

        .accordion-body {
            margin: $gap-medium;

            h4 {
                margin: $gap-medium 0 $gap-medium;
            }

            p {
                margin: 0;
            }

            p, span, li {
                font-size: 1rem !important;
            }
        }

        .accordion-item {
            margin-bottom: 5px;
        }

        table {
            width: 100%;
            margin: $gap-medium 0;
        }
    }
    // Weather data
    canvas {
        max-height: 600px !important;
    }

    .chart-values {
        .form-element__checkbox {
            padding-right: 30px;

            input ~ .form-element__checkbox-checkmark {
                &.pink {
                    border-color: $pink;
                    background: $pink;
                }

                &.light-pink {
                    border-color: $light-pink;
                    background: $light-pink;
                }

                &.light-blue {
                    border-color: $light-sky-blue;
                    background: $light-sky-blue;
                }

                &.blue {
                    border-color: $sky-blue;
                    background: $sky-blue;
                }
            }

            input:checked ~ .form-element__checkbox-checkmark {
                &.pink {
                    background: url(../../img/form/checkbox-check-icon.svg) center center no-repeat $pink;
                }

                &.light-pink {
                    background: url(../../img/form/checkbox-check-icon.svg) center center no-repeat $light-pink;
                }

                &.light-blue {
                    background: url(../../img/form/checkbox-check-icon.svg) center center no-repeat $light-sky-blue;
                }

                &.blue {
                    background: url(../../img/form/checkbox-check-icon.svg) center center no-repeat $sky-blue;
                }
            }

            label {
                font-size: 14px;
            }
        }
    }

    .quick-info {
        margin-top: $gap-medium;
        margin-bottom: $gap-medium;

        p, span {
            @include font-primary-bold();
            color: $color-brand-black;
        }
    }

    .chart-button {
        margin: $gap-medium $gap-small $gap-medium;
        text-transform: none;
    }

    .datacenter-big-image-container {
        text-align: center;
        text-transform: uppercase;
        position: relative;
        max-height: 500px;
        overflow: hidden;
        margin-bottom: $gap-medium;

        img {
            height: 500px;
            object-fit: cover;
            width: 100%;
        }

        p, h3 {
            color: #fff !important;
            margin: 0 0 $gap-medium;
        }

        .datacenter-image-text {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 98%;
            background-color: rgba(0, 0, 0, 0.5);
        }
    }

    .carousel {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    }

    .carousel-item {
        img {
            display: block;
            width: 100%;
            //height: 500px; // This is streching the image
        }
    }

    .carousel-control-next-icon, .carousel-control-prev-icon {
        border: 2px solid $primary;
        opacity: 1;
        width: 60px;
        height: 60px;
    }

    .carousel-control-next-icon {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23002da1'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    }

    .carousel-control-prev-icon {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23002da1'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
    }

    .carousel-control-next, .carousel-control-prev {
        opacity: 1;
    }

    .carousel-control-next {
        right: -19%;
    }

    .carousel-control-prev {
        left: -19%;
    }

    .carousel-text {
        text-align: center;
    }

    .others {
        margin-top: 36px;

        a {
            margin-right: 20px;
        }

        .other-type {
            margin-right: 10px;
            font-weight: 700;
        }
    }

    ul, ol {
        padding-inline-start: 1.5rem;
    }
}
