.form-heading {
    @include font-primary-bold();
    margin: 20px 0 12px;
    font-size: 22px;
    line-height: 26px;
    color: $black;
    text-transform: uppercase;
}

@include media-breakpoint-up(md) {
    .form-heading {
        margin: 16px 0;
    }
}
