.company-page, .company-form-page {
    .back-link {
        margin-top: 23px;
    }

    .company-stats {
        margin-top: 31px;

        &--slide {
            margin-top: 5%;

            @include media-breakpoint-up(sm) {
                margin-top: 10%;
            }
        }
    }

    .company-value-prop-accordion-button {
        cursor: pointer;
        border: none;
        text-transform: none;
        background-color: transparent;
        color: $primary;

        &::before {
            content: '';
            width: 24px;
            height: 24px;
            display: inline-block;
            margin-right: $gap-small;
            background: url(../../img/form/form-collapse-collapsed-icon.svg) center center no-repeat;
            background-size: contain;
            position: relative;
            top: 5px;
        }

        &[aria-expanded="true"] {
            &::before {
                background-image: url(../../img/form/form-collapse-uncollapsed-icon.svg);
            }
        }

        &:focus, &:hover {
            outline: 2px solid $primary;
        }
    }

    .company-show-value-prop-button {
        cursor: pointer;
        border: none;
        text-transform: none;
        background-color: transparent;
        color: $primary;
        display: block;

        &:focus, &:hover {
            outline: 2px solid $primary;
        }
    }

    .company-content {
        margin-top: 40px;
    }

    .company-contacts {
        margin-top: 23px;
    }

    .company-additional {
        margin-top: 54px;
    }

    .company-card {
        margin-top: 22px;
    }

    .company-preview__company-slide {
        .company-content {
            margin-top: 0;
        }

        .company-contacts {
            margin-top: 0;
        }
    }

    .listing-countries-button {
        height: 60px;
        background-color: transparent;
        color: transparent;
        text-transform: uppercase;
        display: block;
        padding: 12px 20px 12px 60px;
        line-height: 36px;
        width: 100%;
        cursor: pointer;
        position: relative;
        text-align: left;
        border: none;

        &:hover,
        &:focus {
            text-decoration: none;
            background-color: none;
            color: none;
        }

        &:focus {
            outline: 2px solid transparent;
        }

        &.disabled {
            cursor: default;
            background-color: none;
            border-color: none;
        }

        &::before {
            content: "";
            position: absolute;
            left: 9px;
            top: 30%;
            transform: translateY(-50%);
            width: 24px;
            height: 24px;
            background: url(../../img/form/form-collapse-collapsed-icon.svg) center center no-repeat;
            background-size: contain;
        }

        &[aria-expanded="true"] {
            &::before {
                background-image: url(../../img/form/form-collapse-collapsed-icon.svg);
            }
        }
    }

    .accordion {
        padding: 1.25rem;
    }

    .modal-header {
        border: none;
    }

    .accordion-button {
        color: $primary;
        border-top: 1px solid $primary;
        background-color: $white;
        box-shadow: none;

        :focus {
            @include focus-styles();
        }
    }

    .accordion-item:first-of-type .accordion-button {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    .accordion-item:last-of-type .accordion-button {
        border-bottom: 1px solid $primary;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }

    .accordion-body {
        border-top: 1px solid $primary;
        color: $black;
    }

    .accordion-item:last-child .accordion-body {
        border-bottom: 1px solid $primary;
        border-top: none;
    }

    .accordion-item {
        border: none;
    }

    .accordion-button.collapsed {
        border-bottom: none;
    }

    .accordion-button::after {
        background-image: url(../../img/form/form-collapse-collapsed-icon.svg);
        transform: scale(.7) !important;
    }

    .accordion-button[aria-expanded="true"]::after {
        transform: translateY(-50%) rotate(180deg);
        background-image: url(../../img/form/form-collapse-uncollapsed-icon.svg);
    }

    .accordion-button::after {
        color: $primary;
        position: absolute;
        left: 0;
    }

    .close-btn {
        position: absolute;
        top: 0;
        right: .8rem;
        padding: 0.5rem;
        background-color: transparent;
        border: none;
        outline: none;
        cursor: pointer;
    }

    .close-btn::before, .close-btn::after {
        content: '';
        position: absolute;
        width: 2px;
        height: 1.5rem;
        background-color: $primary;
    }

    .close-btn::before {
        transform: rotate(45deg);
    }

    .close-btn::after {
        transform: rotate(-45deg);
    }
}

    @include media-breakpoint-up(lg) {
        .company-page, .company-form-page {
            .back-link {
                margin-top: 11px;
            }

            .company-content {
                margin-top: 55px;
            }

            .short-list-trigger {
                margin-top: 95px;
            }

            .company-contacts {
                margin-top: 30px;
            }

            .company-additional {
                margin-top: 92px;
            }

            .company-card {
                margin-top: 0;
            }
        }
    }