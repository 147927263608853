.handbook {
   @include generic-list-styles();

    // This is the same as ps-5 bootstrap class. Doing it like this to avoid using JS. 
    // The content in editorial block will now start in the same "level" as three columns or divisionblock.
    .editorial-block .editor-content {
        padding-left: 3rem !important;
    }

  @include media-breakpoint-up(lg) {
    .page-wrapper {
      padding-top: 6rem;
    }

    .hero-block {
      .hero {
        margin-top: 0;
      }
    }
    // Sticky header
    .header {
      position: fixed;
      height: 6rem;
      top: 0;
      right: 0;
      left: 0;
      z-index: 1030;

      .nav-link {
        padding: 0;
      }
    }
  }

  .header {
    .navbar-toggler-icon {
            background-image: url("../../img/main-menu/menu-icon.svg");
    }

    .navigation__logo {
      grid-area: logo;
      margin: 0;
      align-self: center;
      margin-right: 50px;
    }

    .navigation__main {
      grid-area: main;
      align-self: center;
      display: flex;
      flex-direction: column-reverse;
    }

    .header__logo {
      width: 150px;
    }

    .nav-item {
      list-style-type: none;
    }

    .dropdown-menu {
      position: absolute;
      z-index: 2;
      min-width: $dropdown-min-width;
      padding: $dropdown-padding-y $dropdown-padding-x;
      margin: 0; // Override default margin of ul
      @include font-size($dropdown-font-size);
      color: $dropdown-color;
      text-align: left; // Ensures proper alignment if parent has it changed (e.g., modal footer)
      list-style: none;
    }

    .dropdown-item.active,
    .dropdown-item:active {
      @include font-primary-bold();
      text-decoration: none;
      background-color: transparent;
    }

    li.nav-item {
      position: relative;
    }

    .nav-link:has(+ .dropdown-toggle[aria-expanded="true"]) {
      @include font-primary-bold();
    }

    .dropdown-toggle {
      background-color: transparent;
      border: none;
      color: $finland-blue;
      padding: 0;
      margin: 0;
    }

    .dropdown-toggle[aria-expanded="true"]::after {
      transform: translateY(-50%) rotate(180deg);
      cursor: pointer;
    }

    .dropdown-menu a {
      color: $finland-blue;
    }

    .dropdown-arrow img {
      margin-left: 5px;
    }

    .nav-link[aria-current="page"] {
      @include font-primary-bold();
    }

    .nav-link[aria-expanded="true"] {
      @include font-primary-bold();
    }

    .nav-link:focus,
    .nav__link:hover {
      border-top: none;
      border-bottom: none;
    }

    .nav-link {
      color: $finland-blue;
      margin: 0.5rem;
      display: inline-block;
    }

    .navbar > .container-fluid {
      position: relative;
    }

    .navbar-nav {
      position: absolute;
      z-index: 1;
      background-color: white;
      width: 95%; // So the search icon shows up under the CMS edit page button
      left: 0;
      padding: 0;
      margin: 0;
    }

    @media (max-width: 455px) {
      .dropdown-menu {
        position: inherit;
      }

      .navigation__logo {
        margin-left: 16px;
      }

      .container-mobile {
        padding-right: 0px;
        padding-left: 0px;
      }

      .navbar-nav {
        padding-left: 8px;
      }

      .navigation {
        justify-items: end;
      }
    }
  }
}
