.skip-link {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  
  &:focus {
    clip: auto;
    height: auto;
    overflow: auto;
    position: absolute;
    width: auto;
    background-color: $primary;
    color: $white;
    padding: 1rem;
    z-index: 9999;
    left: 50%;
    transform: translateX(-50%);
    font-size: $text-2;
  }
}
