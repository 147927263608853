.picture-link-box {
    &:not(:last-child) {
        margin-bottom: $gap-xlarge;
    }

    .row:has(&__text-container) {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__image {
        display: none;
        width: 100%;
        /*aspect-ratio: 4 / 3;*/ //Not sure if can be used to force the aspect ratio of images

        @include media-breakpoint-up(md) {
            display: block;
        }
    }

    &__heading {
        margin: 0 0 $gap-medium;

        @include media-breakpoint-up(xl) {
            margin: 0 0 $gap-large;
        }
    }

    &__text {
        @include media-breakpoint-only(md) {
            font-size: $text-3-mobile;
            line-height: $line-height-t3-mobile;
        }
    }

    &__button {
        font-size: $text-3;
        line-height: $line-height-t3;
    }
}
