.company-contacts {
    padding: 28px 23px 36px;
    background-color: #e8f0fa;
}

.company-contacts__label {
    display: block;
    font-size: 18px;
    line-height: 22px;
    color: #002da1;
    text-transform: uppercase;
}

.company-contacts__company-name {
    @include font-primary-bold();
    font-size: 24px;
    line-height: 29px;
    color: #002da1;
    text-transform: uppercase;
    display: block;
    margin-top: 13px;
}

.company-contacts__link {
    font-size: 14px;
    line-height: 17px;
    color: #002da1;
    margin-top: 15px;

    &:focus,
    &:visited,
    &:active,
    &:hover {
        color: #002da1;
        text-decoration: none;
    }
}

.company-contacts__address {
    font-size: 14px;
    line-height: 20px;
    margin-top: 7px;
}

.company-contacts__person {
    font-size: 14px;
    line-height: 20px;
    color: #002da1;
    margin-top: 21px;
}

.company-contacts__person-role {
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: #000000;
}

.company-contacts__person-name {
    @include font-primary-bold();
    font-size: 18px;
    line-height: 22px;
    color: #002da1;
    text-transform: uppercase;
    margin: 5px 0;
    display: inline-block;
}