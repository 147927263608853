.pagination {
  margin-top: 35px;
  float: right;
  text-align: right;

  &--disabled {
    .pagination__page-link {
      opacity: 0.5;
      cursor: default;
      pointer-events: none;
    }
  }
}

.pagination__label {
  @include font-primary-bold();
  font-size: 16px;
  line-height: 19px;
  color: #002da1;
  text-transform: uppercase;
  display: block;
  text-align: left;
}

.pagination__page-list {
  font-size: 0;
  line-height: 0;
}

.pagination__page {
  display: inline-block;
  font-size: 16px;
  line-height: 19px;
  margin-left: 15px;

  &:first-child {
    margin-left: 0;
  }
}

.pagination__page-link {
  @include font-primary-bold();
  color: #002da1;
  cursor: pointer;

  &:hover {
    color: #002da1;
    text-decoration: none;
  }
}

.pagination__page--move {
  + .pagination__page--move {
    margin-left: 10px;
  }

  .pagination__page-link {
    @include font-primary-regular();
  }
}

.pagination__page--nobutton {
    // remove button styles
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    line-height: unset;
    font-size: unset;
}

.pagination__show-all {
  text-transform: uppercase;
  display: block;
  margin-top: 14px;
  color: #002da1;
  font-size: 16px;
  line-height: 19px;

  &:hover {
    color: #002da1;
    text-decoration: none;
  }
}

@include media-breakpoint-up(lg) {
  .pagination__label {
    display: inline-block;
    margin-right: 25px;
  }

  .pagination__page-list {
    display: inline-block;
  }

  .pagination__show-all {
    margin-top: 24px;
  }
}
