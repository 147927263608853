﻿.form-element__spinner {
  width: 40px;
  height: 40px;
  background: url(../../../img/form/spinner-icon.svg) center center no-repeat;
}

.form-element__spinner-white {
    width: 40px;
    height: 40px;
    background: url(../../../img/form/spinner-icon-white.svg) center center no-repeat;
}
