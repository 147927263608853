.company-preview {
    &__content-container {
        background-color: $light-blue;
        padding: 2rem 0.5rem;

        @include media-breakpoint-only(lg) {
            padding: 2rem 1rem;
        }

        @include media-breakpoint-up(xl) {
            padding: 5rem;
        }

        @include media-breakpoint-up(xxl) {
            padding: 7rem;
        }
    }

    &__tablist {
        margin-bottom: $gap-xlarge;
        list-style: none;
    }

    &__company-page {
        background-color: $white;
        padding: $gap-medium;

        @include media-breakpoint-up(md) {
            padding: $gap-large $gap-xlarge;
        }
    }

    &__company-card {
        width: 100%;
        padding: $gap-xxlarge $gap-medium;
        background: white;

        @include media-breakpoint-up(md) {
            width: 580px;
            padding: $gap-xxxlarge $gap-xxlarge;
            background: white;
            margin: 0 auto;
        }
    }

    @include media-breakpoint-up(lg) {
        .company-intro__logo-wrapper {
            position: relative;
            bottom: unset;
        }
    }
    
/*    @include media-breakpoint-up(xl) {
        .company-intro__logo-wrapper {
            position: absolute;
            bottom: 30px;
        }
    }*/

    .company-preview__company-slide {
        background-color: $white;
        padding: 1rem 0.5rem;

        @include media-breakpoint-only(sm) {
            padding: 1rem;
        }
        @include media-breakpoint-up(md) {
            padding: 2rem;
        }

        .company-slide {
            margin-bottom: 1rem;
        }

        .company-content {
            &__heading {
                margin: 0 0 6px;
                font-size: 0.4rem;
                line-height: 0.5rem;
            }
    
            p {
                margin-bottom: 10px;
                font-size: 40%;
                line-height: 100%;
            }

            @include media-breakpoint-only(md) {
                &__heading {
                    margin: 0 0 0.5rem;
                    font-size: 0.75rem;
                    line-height: 1rem;
                }
        
                p {
                    font-size: 50%;
                    line-height: 100%;
                    margin-bottom: 0.75rem;
                }
            }

            @include media-breakpoint-up(lg) {
                &__heading {
                    margin: 0 0 12px;
                    font-size: 0.875rem;
                    line-height: 1.125rem;
                }
        
                p {
                    font-size: 0.75rem;
                    line-height: 1rem;
                    margin-bottom: 1rem;
                }
            }
        }
    
        .company-contacts {
            padding: 14px 10px;

                &__label, &__person-role, &__company-name, &__person-name, &__link, &__address, a {
                    font-size: 0.4rem;
                    line-height: 0.5rem;
                }

                &__company-name {
                    margin-top: 8px;
                }

                &__address {
                    margin-top: 5px;
                    margin-bottom: 10px;
                }

            a::after {
                content: none;
            }

            @include media-breakpoint-only(md) {
                padding: 14px 10px;

                &__label, &__person-role, &__company-name, &__person-name, &__link, &__address, a {
                    font-size: 0.5rem;
                    line-height: 0.75rem;
                }

                &__company-name {
                    margin-top: 10px;
                }

                &__address {
                    margin-top: 5px;
                    margin-bottom: 12px;
                }
            }

            @include media-breakpoint-up(lg) {
                padding: 28px 23px 36px;

                &__label, &__person-role, &__company-name, &__person-name, &__link, &__address, a {
                    font-size: 0.875rem;
                    line-height: 1.125rem;
                }
            }
        }
    }

    &__slide-top-container {
        padding: 0 1rem 1rem;
    }

    &__slide-top-image {
        display: block;
        max-height: 45px;
        max-width: 100%;
    }

    .company-intro__heading {
        font-size: $heading-h1;
        line-height: $line-height-h1;
        border-bottom: 5px solid #002da1;
        padding-bottom: $gap-medium;

        @include media-breakpoint-up(lg) {
            font-size: 3.5rem;
            line-height: 4rem;
        }

        @include media-breakpoint-up(lg) {
            font-size: $heading-hero;
            line-height: $line-height-hero;
        }
    }
    
    .company-intro__description {
        p {
            font-size: $text-3;
            line-height: $line-height-t3;

            @include media-breakpoint-up(lg) {
                font-size: $text-2;
                line-height: $line-height-t2;
            }
        }
    }

    .company-content  {
        &__heading {
            font-size: $text-3;
            line-height: $line-height-t3;

            @include media-breakpoint-up(lg) {
                font-size: $text-2;
                line-height: $line-height-t2;
            }
        }

        p {
            font-size: $text-4;
            line-height: $line-height-t4;
    
            @include media-breakpoint-up(lg) {
                font-size: $text-3;
                line-height: $line-height-t3;
            }
        }
    }

    .company-stats {
        &__item, &__heading {
            font-size: $text-4;
            line-height: $line-height-t4;
    
            @include media-breakpoint-up(lg) {
                font-size: $text-3;
                line-height: $line-height-t3;
            }
        }
    }
}