﻿.testimonial-block {
    .video-container {
        height: 100%;
        width: 100%;
    }

    #youtubeVideo {
        height: 350px;

        @include media-breakpoint-down(lg) {
            height: 200px;
        }
    }

    .icarus-video-container {
        height: 340px;
        width: 100%;
    }

    .testimonial-quote {
        &:before {
            content: url(../../img/general/quote.svg);
        }
    }
}
