// This is in global context in order to apply it correctly in CompanySearchResultsPage
.offering-cards-container {
    margin-top: $gap-large;
    margin-bottom: $gap-large;
}

.offering-card {
    height: 100%;
    display: flex;
    flex-direction: column;

    .btn-container {
        display: flex;
    }

    .btn-container .btn {
        padding: $gap-small $gap-medium $gap-small $gap-medium;
        white-space: nowrap;
    }

    .offering-card__link {
        text-decoration: none;
        color: initial;
        padding: 5px;
        flex-direction: column;
        border-top: 1px solid transparent;
        border-bottom: 1px solid transparent;
        margin: $gap-medium 0;

        &:focus, &:hover {
            text-decoration: none;
            background-color: $light-blue;
        }
    }

    .offering-card__offering-name {
        @include font-primary-bold();
        margin: 0 0 $gap-small 0;
    }

    .offering-card__description {
        margin-bottom: 0;
    }

    .offering-card__button {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    @include media-breakpoint-up(lg) {
        .btn-container .btn {
            width: 100%;
        }
    }
}
