/*
  Variables, other than design tokens.
*/

// Dimension
$nav-icon-size: 1.5rem;
$nav-icon-container-size: 2rem;

// Borders
$border-primary--thin: 1px solid $primary;
$border-transparent--thin: 1px solid transparent;
$border-primary--medium: 2px solid $primary;
$border-transparent--medium: 2px solid transparent;

