.form-element {
  margin-bottom: 16px;
  position: relative;

  &--no-space {
    margin-bottom: 0;
  }

  &__element-delete {
    width: 22px;
    height: 27px;
    background: url(../../../img/form/delete-icon.svg) center center no-repeat;
    position: absolute;
    top: 17px;
    right: 59px;
    cursor: pointer;
    border: none;
  }

  //We didn't find better solution for this case with Jonathan
  .EPiServerForms {
    .Form__Status {
      .Form__Status__Message {
        padding: 0px;
        margin: 0px;
      }
    }
  }
}

.form-element__help {
  position: absolute;
  top: 0;
  right: 0;
  background: url(../../../img/form/help-icon.svg) center center no-repeat;
  width: 28px;
  height: 27px;
}

.form-element__label {
  &--file {
    height: 60px;
    line-height: 60px;
    background-color: #f5f5f5;
    color: $primary;
    text-transform: uppercase;
    display: block;
    padding: 12px 36px 12px 20px;
    font-size: 18px;
    line-height: 36px;
    cursor: pointer;
    position: relative;
  }
}

.form-element__tooltip-toggle {
  @include media-breakpoint-up(lg) {
      position: absolute;
      top: 0;
      right: -12px;
  }
}

.form-element__tip-container.tooltip__tip-container {
  width: 100%;
  position: absolute;

  @include media-breakpoint-up(lg) {
    position: absolute;
    top: 0;
    left: 103%;
  }
}
