.hero {
  &__help-text-container {
    position: absolute;
    bottom: 2rem;
    left: 1.25rem;

    @include media-breakpoint-up(sm) {
      display: none;
    }
  }

  &__help-text {
    display: block;
    color: $white;
    font-size: $text-1-mobile;
    line-height: $line-height-t1-mobile;
    text-transform: uppercase;
    margin: 0;
    
    &--bold {
      @include font-primary-bold();
    }
  }
}
