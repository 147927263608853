.company-stats {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.company-stats__item {
    font-size: 18px;
    line-height: 22px;
    color: #002da1;

    &--black {
        color: #000;
    }
}

.company-stats__heading {
    font-size: 20px;
    line-height: 24px;
    display: block;
    color: #000000;
    margin-bottom: 5px;
}

.company-stats__icon {
    width: 18px;
    height: 18px;
    display: inline-block;
    position: relative;
    margin-right: 5px;

    &--globe {
        background: url(../../img/company-stats/company-stats-globe-icon.svg) center center no-repeat;
        top: 2px;
    }

    &--puzzle {
        background: url(../../img/company-stats/company-stats-puzzle-icon.svg) center center no-repeat;
        top: 2px;
    }

    &--tag {
        background: url(../../img/company-stats/company-stats-tag-icon.svg) center center no-repeat;
        top: 2px;
    }
}

@include media-breakpoint-down(lg) {
    .company-stats__item {
        + .company-stats__item {
            margin-top: 25px;
        }
    }
}

@include media-breakpoint-up(lg) {
    .company-stats {
        flex-direction: row;
    }

    .company-stats__item {
        flex-basis: 30%;
        
        &:nth-child(1) {
            order: -2;
        }

        &:nth-child(3) {
            order: -1;
            flex-basis: 60%;
            margin-left: $grid-gutter-width;
        }

        &:nth-child(2) {
            margin-top: 28px;
        }

        &:nth-child(4),
        &:nth-child(5) {
            margin: 28px 0 0 $grid-gutter-width;
        }
    }
}

.company-stats--slide {
    flex-direction: row;
    flex-wrap: nowrap;
}

.company-stats__item--slide {
    font-size: 20%;
    line-height: 100%;
    flex-basis: 50%;
    
    @include media-breakpoint-up(sm) {
        font-size: 30%;
        line-height: 100%;
    }
    
    @include media-breakpoint-up(md) {
        font-size: 40%;
        line-height: 100%;
    }
    
    @include media-breakpoint-up(lg) {
        font-size: 60%;
        line-height: 100%;
    }
    
    @include media-breakpoint-up(xl) {
        font-size: 80%;
        line-height: 100%;
    }
    

    &:nth-child(1), &:nth-child(2) {
        order: unset;
        margin-top: 0;
    }
}

.company-stats__heading--slide {
    font-size: 90%;
    line-height: 100%;

    @include media-breakpoint-only(xs) {
        margin-bottom: 0;
    }

    @include media-breakpoint-up(sm) {
      font-size: 90%;
      line-height: 100%;
    }

    @include media-breakpoint-up(md) {
      font-size: 90%;
      line-height: 100%;
    }

    @include media-breakpoint-up(lg) {
      font-size: 90%;
      line-height: 100%;
    }

    @include media-breakpoint-up(xl) {
      font-size: 90%;
      line-height: 100%;
    }
  }

.company-stats__icon--slide {
    @include media-breakpoint-down(lg) {
        width: 12px;
        height: 12px;
        background-size: 70%;
    }
}
