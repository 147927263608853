* {
  @include font-primary-regular();
  // Keep this property because the font looks different on mac / ios devices. This property only works on mac devices.
  // More info here: https://developer.mozilla.org/en-US/docs/Web/CSS/font-smooth
  -webkit-font-smoothing: antialiased;
}

body {
  font-size: 100%;
  // font-size: 16px; // Never you 16px as a base font size. Read more here: https://adrianroselli.com/2024/03/the-ultimate-ideal-bestest-base-font-size-that-everyone-is-keeping-a-secret-especially-chet.html
  line-height: 22px;
}

.back-link {
  color: #002ea2;
  font-size: 20px;
  line-height: 30px;
  padding-left: 42px;
  position: relative;
  display: inline-block;

  &::before {
    content: "";
    height: 30px;
    width: 30px;
    background: url(../../img/general/back-link.svg) center center no-repeat;
    position: absolute;
    left: 0;
    top: 0;
  }

  &:hover {
    text-decoration: none;
  }
}

.hidden {
  display: none;
}

.right {
  float: right;
}

.block {
  margin-bottom: $gap-xlarge;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

abbr {
  text-decoration: none;
}

ul {
  list-style: disc;
}

figcaption {
  @include font-primary-regular();
  color: $primary;
  margin: 0 0 $gap-large;
  font-size: $text-2-mobile;
  line-height: $line-height-t2-mobile;

  @include media-breakpoint-up(lg) {
    font-size: $text-2;
    line-height: $line-height-t2;
  }
}

img {
  max-width: 100%;
  width: auto;
  height: auto;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.not-sr-only {
  position: static;
  width: auto;
  height: auto;
  padding: 0;
  margin: 0;
  overflow: visible;
  clip: auto;
  white-space: normal;
}
