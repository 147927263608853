.company-materials {
    background-color: #e8f0fa;
    padding: 28px 23px 36px;
    margin-top: 29px;
}

.company-materials__heading {
    font-size: 24px;
    line-height: 29px;
    text-transform: uppercase;
    margin: 0;
}

.company-materials__download-link {
    font-size: 18px;
    line-height: 22px;
    color: #002da1;
    text-transform: uppercase;
    display: inline-block;
    margin-top: 19px;

    &::after {
        content: '';
        width: 20px;
        height: 23px;
        display: inline-block;
        position: relative;
        top: 2px;
        margin-left: 9px;
        background: url(../../img/company-materials/company-materials-download-link-icon.svg) center center no-repeat;
    }
}