.text-block {
  &__heading {
    margin-bottom: $gap-large;
  }

  &__text-with-border {
    border-left: 4px solid #002da1;
    padding-left: $gap-medium;

    @include media-breakpoint-only(md) {
      margin-left: 3rem;
    }

    @include media-breakpoint-up(lg) {
      margin-left: $gap-xlarge;
      padding-left: $gap-large;
    }
  }
}