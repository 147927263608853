.search-field {
    text-align: center;
}

.search-field__heading {
    @include font-primary-bold();
    font-size: 48px;
    line-height: 58px;
    text-transform: uppercase;
    margin: 0;
}

.search-field__description {
    margin-top: 29px;

    p {
        font-size: 22px;
        line-height: 26px;
        color: #002da1;
    }
}

.search-field__container {
    margin-top: 30px;
}

.search-field__input {
    width: 100%;
    font-size: 18px;
    line-height: 22px;
    padding: 18px 22px;
    border: 1px solid #979797;
    background-color: #ffffff;

    // Placeholder
    &:-moz-placeholder {
        color: #979797;
    }

    &::-moz-placeholder {
        color: #979797;
    }

    &:-ms-input-placeholder {
        color: #979797;
    }

    &::-webkit-input-placeholder {
        color: #979797;
    }

    &:focus {
        outline: none;
    }
}

.search-field__btn {
    width: 100%;
    margin-top: 20px;
}

.search-field__trending-words {
    text-align: left;
    font-size: 14px;
    line-height: 17px;
    margin-top: 20px;

    a {
        color: #002da1;
    }
}

.search-field__autocomplete {
    padding: 10px 0 22px;
    background-color: #ffffff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);

    &.ui-widget-content {
        border: none;
    }

    .ui-menu-item-wrapper {
        padding: 13px 16px 13px 46px;
        position: relative;
        line-height: 16px;
        border: none;
        color: #000000;

        &::before {
            content: '';
            background: url(../../img/search-field/search-field-autocomplete-icon.svg) center center no-repeat transparent;
            width: 18px;
            height: 16px;
            display: inline-block;
            position: absolute;
            left: 16px;
            top: 13px;
        }

        &.ui-state-active {
            background-color: transparent;
            border: none;
            color: #000000;
            margin: 0;
        }
    }
}

.search-result-text {
    margin-top: 32px;
    text-align: center;
    font-size: 40px;
    color: $primary;
}

.search-filters {
    margin-top: 32px;
    margin-bottom: 32px;

    .form-element__checkbox {
        margin-right: 20px;
    }
}

#filter::after {
    content: '';
    background: url(../../img/button/sliders-icon.svg) center center no-repeat transparent;
    width: 18px;
    height: 16px;
    display: inline-block;
    // position: absolute;
    // left: 16px;
    // top: 13px;  
    color:#002da1;
}

@include media-breakpoint-up(lg) {
    .search-field {
        margin: 0 70px;
    }

    .search-field__description {
        margin-top: 20px;
    }

    .search-field__container {
        margin-top: 45px;
    }

    .search-field__container-inner {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        width: 100%;
    }

    .search-field__input {
        display: block;
        flex: 1 1 auto;
        width: 1%;
        min-width: 0;
    }

    .search-field__btn {
        margin: 0;
        white-space: nowrap;
        display: block;
        align-items: center;
        width: auto;

        &:hover .btn__icon--search {
            background: url(../../img/button/search-secondary-icon.svg) center center no-repeat;
        }
    }
}