// Inputs
.form-element__input-container {
    position: relative;
    display: block;
    margin-right: 36px;
}

.form-element__input {
    @include font-primary-regular();
    height: 60px;
    padding: 0 20px;
    width: 100%;
    outline: none;
    line-height: 60px;
    position: relative;
    -moz-appearance: textfield;
    
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    // Remove of the IE clean X button
    &::-ms-clear {
        display: none;
    }
}

// Inline version
.form-element--inline {
    .form-element__input-container {
        display: inline-block;
    }
}

input[type="text"],
input[type="number"],
input[type="email"],
select {
    &:focus {
        @include focus-styles();
    }
}

// Auto complete
.ui-autocomplete {
    background: #f5f5f5;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    color: $primary;
    line-height: 35px;
    max-height: 350px; // Limit the height of the jquery autocomplete box
    overflow-y: auto; // Add vertical scrollbar automatically when needed. 
    overflow-x: hidden; // Do not add horizontal scrollbar at all.
    padding-bottom: 5px; // Prevent scrollbar from appearing when very few items in suggestion menu eg.1 item.

    .ui-menu-item-wrapper {
        padding-left: 20px;
        font-size: 18px;
    }
}
