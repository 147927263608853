// Font size variables
// Desktop
$heading-hero: 4rem; // 64px
$heading-h1: 3rem; // 48px
$heading-h2: 2.5rem; // 40px
$heading-h3: 2rem; // 32px
$heading-h4: 1.5rem; // 24px
$text-1: $heading-h4; // 24px
$text-2: 1.25rem; // 20px
$text-3: 1rem; // 16px
$text-4: 0.875rem; // 14px

// Finnish solutions Desktop
$heading-fs-h1: 2rem; // 32px
$heading-fs-h2: 1.75rem; // 28px
$heading-fs-h3: 1.5rem; // 24px
$heading-fs-h4: 1.25rem; // 20px
$text-fs-1: 1rem; // 16px

// Mobile
$heading-hero-mobile: 2.875rem; // 46px
$heading-h1-mobile: 2.25rem; // 36px
$heading-h2-mobile: 1.875rem; // 30px
$heading-h3-mobile: 1.625rem; // 26px
$heading-h4-mobile: 1.375rem; // 22px
$text-1-mobile: 1.5rem; // 24px
$text-2-mobile: $heading-h4-mobile; // 22px
$text-3-mobile: 1.125rem; // 18px
$text-4-mobile: 1rem; // 16px

// Finnish solutions Mobile
$heading-fs-h1-mobile: 1.625rem; // 26px
$heading-fs-h2-mobile: 1.375rem; // 22px
$heading-fs-h3-mobile: 1.375rem; // 26px
$heading-fs-h4-mobile: 1.25rem; // 20px
$text-fs-1-mobile: 1rem; // 16px

// Line height variables
// Desktop
$line-height-hero: 4.5rem; // 72px
$line-height-h1: 3.25rem; // 52px
$line-height-h2: 2.75rem; // 44px
$line-height-h3: 2.25rem; // 36px
$line-height-h4: 1.75rem; // 28px
$line-height-t1: 2rem; // 32px
$line-height-t2: 1.75rem; // 28px
$line-height-t3: 1.5rem; // 24px
$line-height-t4: 1.25rem; // 20px

// Finnish solutions Desktop
$line-height-fs-h1: 2.375rem; // 38px
$line-height-fs-h2: 2.125rem; // 34px
$line-height-fs-h3: 1.75rem; // 28px
$line-height-fs-h4: 1.5rem; // 24px
$line-height-fs-t1: 1.375rem; // 32px

// Mobile
$line-height-hero-mobile: 3.375rem; // 54px
$line-height-h1-mobile: 2.5rem; // 40px
$line-height-h2-mobile: 2.125rem; // 34px
$line-height-h3-mobile: 1.875rem; // 30px
$line-height-h4-mobile: 1.625rem; // 26px
$line-height-t1-mobile: 2rem; // 32px
$line-height-t2-mobile: $line-height-h3-mobile; // 30px
$line-height-t3-mobile: $line-height-h4-mobile; // 26px
$line-height-t4-mobile: 1.5rem; // 24px

// Spacing
// Rem units
$gap-small: 0.5rem; // 8px
$gap-medium: 1rem; // 16px
$gap-large: 2rem; // 32px
$gap-xlarge: 4rem; // 64px
$gap-xxlarge: 6rem; // 96px
$gap-xxxlarge: 8rem; // 128px

// Px units
// Use if spacing should remain the same even though text is zoomed.
$gap-small-px: 8px;
$gap-medium-px: 16px;
$gap-large-px: 32px;
$gap-xlarge-px: 64px;
$gap-xxlarge-px: 96px;
$gap-xxxlarge-px: 128px;