.short-list-trigger {
    margin: 28px 0;
    color: $primary;
    font-size: 24px;
    line-height: 29px; // value from figma is 28.8px but I rounded it to 29px. For some reason existing design text and heading styles are not used.
    padding-left: 42px;
    position: relative;
    display: inline-block;

    @include media-breakpoint-up(lg) {
        font-size: 26px;
        line-height: 31px; // value from figma is 31.2px but I rounded it to 31px. For some reason existing design text and heading styles are not used.
    }

    &::before {
        content: '';
        width: 34px;
        height: 34px;
        background: url(../../img/short-list-trigger/short-list-trigger-icon.svg) center center no-repeat;
        position: absolute;
        left: 0;
    }

    strong {
        @include font-primary-bold();
        text-transform: uppercase;
        color: $primary;
    }
}

.short-list-trigger__count {
    @include font-primary-bold();
    background-color: #d20000;
    width: 17px;
    height: 17px;
    border-radius: 17px;
    color: #ffffff;
    font-size: 12px;
    line-height: 17px;
    text-align: center;
    position: absolute;
    top: 3px;
    left: 20px;
}

.short-list-trigger__text {
    white-space: nowrap;
    color: $primary;
}
