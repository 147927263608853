.content {
    &__buttons {
        margin: $gap-large 0;

        .btn:first-child {
            margin-bottom: $gap-large;
            margin-right: $gap-large;
        }

        .btn {
            font-size: $text-3;
            line-height: $line-height-t3;
        }
    }

  &__btn {
    display: inline-block;
    font-size: 16px;
    line-height: 24px;
  }
}