.hero {
  &__landing-page-content {
    display: flex;
    width: 100%;
    height: 21.875rem;
    background-color: $primary;

    @include media-breakpoint-up(sm) {
      min-height: 23rem;
    }
    @include media-breakpoint-up(md) {
      min-height: 31.25rem;
    }
    @include media-breakpoint-up(lg) {
      min-height: 41rem;
    }

    &--left {
      align-items: center;
    }

    &--right {
      flex-direction: row;
      flex-wrap: wrap;
      padding: 0;
      background-color: unset;
      justify-content: initial;
      align-items: initial;
      background-color: $white;

      @include media-breakpoint-up(sm) {
        width: 50%;
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }
}
