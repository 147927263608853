.offering-collections-highlight {
    margin-top: $gap-xlarge;

    .offering-collections-highlight__heading {
        @include font-primary-regular();
        font-size: 40px;
        line-height: 48px;
        color: $primary;
        margin: 0;
        text-transform: uppercase;

        strong {
            @include font-primary-bold();
        }
    }

    .offering-collections-highlight__description {
        margin: 17px 0 31px;

        p {
            font-size: 22px;
            line-height: 26px;
        }
    }

    .offering-collections-highlight__link {
        @include font-primary-bold();
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: $gap-medium;
        text-transform: uppercase;
        color: $primary;
        min-height: 120px;
        background-color: #f5f5f5;
        margin-right: $gap-large;
        margin-bottom: $gap-large;
        overflow: hidden;

        &:hover, &:focus {
            color: $white;
            background-color: $primary;
            text-decoration: none;
        }
    }

    .offering-collections-highlight__image {
        min-height: 29px;
    }
}
