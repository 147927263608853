.articles {
  background-color: #e4dffc;
  padding: $gap-xlarge 0;

  &__button {
    margin: 0 0 1.125rem;

    @include media-breakpoint-up(md) {
      margin: 0;
    }
  }

  &__cards {
    @include media-breakpoint-only(md) {
      .link-card {
        padding-left: 0.5rem;
      }
    }
    @include media-breakpoint-up(md) {
      margin-top: -0.5rem;
    }

    @include media-breakpoint-up(lg) {
      margin-top: -1rem;
    }
  }
}

article ol {
    @include font-primary-regular;
    color: $black;
    margin-left: $gap-large;
}

article ul {
    @include font-primary-regular;
    color: $black;
    list-style-type: disc;
    margin-left: $gap-large;
}