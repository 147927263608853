.list-header {
  margin-top: 55px;
}

.list-header-tools {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: flex-end;

  div.list-sort-panel {
    margin-top: 0;
  }

  .list-sort-panel {
    order: 1;
  }

  .list-header__actions {
    display: flex;
    flex-direction: column;
    order: 2;
    gap: 1.5rem;

    button.list-header__export {
      order: 1;
      width: auto;
    }

    .short-list-trigger {
      order: 2;
    }

    .short-list-trigger,
    button.list-header__export {
      align-self: flex-end;
      margin: 0;
    }
  }
}

.list-header__result {
  margin-top: $gap-large;
  padding: 25px 0;
  border-top: 1px solid #0029a8;
  border-bottom: 1px solid #0029a8;
  text-align: center;
  font-size: 24px;
}

@include media-breakpoint-down(md) {
  .list-header-tools {
    flex-direction: column;
    gap: 1.5rem;
    align-items: flex-start;

    .list-header__actions {
      order: 1;

      .short-list-trigger,
      button.list-header__export {
        align-self: flex-start;
      }
    }

    .list-sort-panel {
      order: 2;
    }
  }
}
