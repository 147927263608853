.company-content {
    p {
        font-size: 20px;
        line-height: 22px;
        color: #000;
    }
}

.company-content__heading {
    margin: 20px 0 10px;
}

@include media-breakpoint-up(lg) {
    .company-content__heading {
        margin: 28px 0 16px;
    }   
}