$border-transparent: 2px solid transparent;
$border-primary: 2px solid $primary;
$hover-padding: $gap-small;

.events {
  &__event {
    display: flex;
    margin-bottom: 1.5rem;

    @include media-breakpoint-up(lg) {
      &:not(:last-child) {
        margin-bottom: $gap-xlarge - $hover-padding;
      }
    }
  }

  &__link {
    padding: $hover-padding 0;
    border-top: $border-transparent;
    border-bottom: $border-transparent;

    &:hover {
      text-decoration: none;
    }

    &:focus {
      outline: 2px solid transparent;
    }

    &:hover, &:focus {
      border-top: $border-primary;
      border-bottom: $border-primary;
    }
  }

  &__main-heading {
    @include media-breakpoint-down(md) {
      margin-bottom: $gap-large;
    }

    @include media-breakpoint-up(md) {
      margin-top: 0.625rem;
    }
  }

  &__number {
    font-size: $heading-h1-mobile;
    line-height: $line-height-h1-mobile;
    margin: 0 3rem 0 0;
    width: 60px;
    min-width: 60px;
    max-width: 60px;

    @include media-breakpoint-up(md) {
      margin-top: 0.625rem;
    }

    @include media-breakpoint-up(lg) {
      font-size: $heading-hero-mobile;
      line-height: $line-height-hero-mobile;
      width: 75px;
      min-width: 75px;
      max-width: 75px;
    }
  }

  &__label {
    margin-bottom: $gap-medium;
  }

  &__text {
    margin-bottom: 0;

    @include media-breakpoint-only(md) {
      font-size: $text-3-mobile;
      line-height: $line-height-t3-mobile;
    }
  }
}