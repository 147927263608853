.tabs {
  &__tablist {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem
  }

  &__tab {
    @include font-primary-bold;
    display: block;
    border: none;
    background-color: transparent;
    color: $primary;
    font-size: $text-3;
    line-height: $line-height-t3;

    @include media-breakpoint-up(md) {
      font-size: $text-3;
      line-height: $line-height-t3;
    }

    @include media-breakpoint-up(lg) {
      font-size: $text-1;
      line-height: $line-height-t1;
    }

    &:hover {
      cursor: pointer;
    }

    &--active {
      @include font-primary-bold;
      font-size: $text-1;
      line-height: $line-height-t1;

      @include media-breakpoint-up(md) {
        font-size: $heading-h4;
        line-height: $line-height-h4;
      }

      @include media-breakpoint-up(lg) {
        font-size: $heading-h3;
        line-height: $line-height-h3;
      }
    }
  }

  @include media-breakpoint-down(sm) { 
    &__tablist {
      display: flex;
      flex-direction: column;
    }
 }

  &__panel {
    display: block;

    &--hidden {
      display: none;
    }
  }
}