.content-small-image {
  &__card {
    display: flex;
    flex-direction: column;
    gap: $gap-medium;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
      gap: $gap-large;
    }
  }

  &__image-container {
    width: 100%;
    display: none;

    @include media-breakpoint-up(md) {
      display: block;
    }

    @include media-breakpoint-up(lg) {
      width: 33.3%;
    }
  }

  &__text-container {
    width: 100%;

    @include media-breakpoint-up(lg) {
      width: 66.7%;
    }
  }

  .heading--h3 {
    margin-bottom: $gap-large;
  }

  .btn {
    font-size: $text-3;
    line-height: $line-height-t3;
  }
}