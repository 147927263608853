$dark-blue: #000068;
$light-blue: #dbeefe;
$black: #000000;
$electric-blue: #0048ff;
$pink: #d80c8c;
$light-pink: rgba(217, 0, 134, 0.5);
$sky-blue: #00afe6;
$light-sky-blue: #ace0f0;
$finland-blue: #002ea2;
$light-green: #def4d1;
$light-gray: #d2d2d2;
$bf-blue: #002ea2;
$bf-gray: #707070;
$bf-light-gray: #f5f5f5;
$virtu-blue: #007da5;
$bf-pink: #9e0085;
$primary: #002da1; // Taken from libs/_bootstrap.scss primary value.
