.breadcrumbs {
    font-size: 0;
    line-height: 0;
}

.breadcrumbs--international {
    list-style: none;
    margin-top: $gap-medium;
    margin-bottom: $gap-medium;
}

@include media-breakpoint-up(md) {
    .breadcrumbs--international {
        list-style: none;
        margin-top: $gap-medium;
        display: flex;
    }

   
}

.breadcrumbs__item:not(.breadcrumbs__item--international) {
    display: inline-block;
    padding: 0 10px;
    position: relative;
    font-size: 22px;
    line-height: 32px;

    &::before {
        content: '';
        width: 1px;
        background-color: #002da1;
        position: absolute;
        top: 4px;
        bottom: 4px;
        left: 0;
    }

    &:first-child {
        padding-left: 0;

        &::before {
            display: none;
        }
    }
}

.breadcrumbs__item--international {
    display: flex;
    align-items: center;
    font-size: $text-3;
    line-height: $line-height-t4;
}

.breadcrumbs__link {
    @include font-primary-regular;
    color: $primary;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;

    &[aria-current="page"] {
        @include font-primary-bold;
    }

    &:hover {
        text-decoration: none;
    }

    &:focus {
        outline: transparent;
    }

    &:hover, &:focus {
        border-top: 1px solid $primary;
        border-bottom: 1px solid $primary;
    }
}

.breadcrumb__arrow {
    &::after {
        content: '';
        width: 24px;
        height: 14px;
        display: inline-block;
        position: relative;
        top: 2px;
        background: url(../../icons/arrow-right.svg) center center no-repeat;
    }
}

.breadcrumbs__icon {
    padding: 0 $gap-small;
}

@include media-breakpoint-up(lg) {
    .breadcrumbs__item:not(.breadcrumbs__item--international) {
        padding: 0 5px;
        font-size: 18px;
        line-height: 22px;

        &::before {
            top: 1px;
            bottom: 1px;
        }
    }
}