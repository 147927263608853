.form-collapse {
  position: relative;
  width: 90%;

  + .form-collapse {
    margin-top: 19px;
  }
}

.form-collapse__toggler {
  height: 60px;
  background-color: #f5f5f5;
  color: $primary;
  text-transform: uppercase;
  display: block;
  padding: 12px 20px 12px 60px;
  font-size: 18px;
  line-height: 36px;
  width: 100%;
  cursor: pointer;
  position: relative;
  text-align: left;
  border: none;

  &::before {
    content: "";
    width: 50px;
    height: 50px;
    position: absolute;
    top: 5px;
    left: 5px;
    background: url(../../../img/form/form-collapse-collapsed-icon.svg) center
      center no-repeat;
  }

  &[aria-expanded="true"] {
    &::before {
      background-image: url(../../../img/form/form-collapse-uncollapsed-icon.svg);
    }
  }
}

.form-collapse__content {
  background-color: #f5f5f5;
}

.form-collapse__content-inner {
  padding: 13px 17px 9px;
  overflow: hidden;

  hr {
    border: none;
    border-top: 1px solid rgba(#979797, 0.3);
    margin: 15px 0 20px;
  }
}

.form-collapse__tooltip-toggle {
  position: absolute;
  top: 0;
  right: -44px;
}

.form-collapse__tip-container.tooltip__tip-container {
  width: 100%;

  @include media-breakpoint-only(lg) {
    max-width: 20rem;
  }
  @include media-breakpoint-up(lg) {
    position: absolute;
    top: 0;
    left: 109%;
  }
}

@include media-breakpoint-up(md) {
  .form-collapse__column {
    width: 33.33333%;
    float: left;
  }
}

@include media-breakpoint-up(md) {
  .form-collapse {
    width: 95%;
  }
}
