// General structure of the page - always taking 100 % of height
html {
  height: 100%;
  width: 100%;
}

body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  // overflow-x: hidden; // Commented out to allow horizontal scrolling. This is not supposed to be handled by body element and it the causes other errors - https://github.com/tailwindlabs/headlessui/issues/839
  background-color: #dfdfdf;

  .external-link svg {
    width: 15px;
    margin-left: 5px;
    margin-bottom: -1px;
  }
}

.page-wrapper {
  display: table;
  table-layout: fixed;
  height: 100%;
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  background-color: #fff;
}

.header {
  display: table-row;
  height: 1px;
}

.page-content {
  display: table-row;
  height: 100%;
}

.footer {
  display: table-row;
  height: 1px;
}

.content-wrapper {
  padding-bottom: 50px;
}

.container-wide {
  overflow-x: hidden;
}
