﻿// Alerts and messages
.form-element__alert-container {
  position: relative;
  display: block;
  margin-right: 36px;
}

.form-element__alert {
  position: relative;

  .form-element__alert-top {
    box-sizing: border-box;
    position: absolute;
    width: 100%;
    height: 7px;
    background: #d4564a;
    border: 1px solid;
    border-radius: 6px 6px 0px 0px;
    z-index: 1;
  }

  .form-element__alert-msg {
    box-sizing: border-box;
    position: relative;
    width: 100%;
    min-height: 69px;
    background: #ffffff;
    border: 1px solid;
    border-radius: 6px;
    width: 100%;

    &.deployment-msg {
      height: 285px;
    }

    .form-element__alert-icon {
      width: 25px;
      height: 24px;
      -webkit-mask: url(../../../img/form/alert-icon.svg) center center
        no-repeat;
      mask: url(../../../img/form/alert-icon.svg) center center no-repeat;
      position: absolute;
      top: 23px;
      left: 12px;
    }

    p {
      margin-top: 7px;
      padding: 17px;
      margin-left: 30px;
    }
  }

  &.alert-danger {
    p {
      color: $danger;
    }

    .form-element__alert-top {
      background: $danger;
      border-color: $danger;
    }

    .form-element__alert-msg {
      border-color: $danger;

      .form-element__alert-icon {
        background-color: $danger;
      }
    }
  }

  &.alert-success {
    p {
      color: $success;
    }

    .form-element__alert-top {
      background: $success;
      border-color: $success;
    }

    .form-element__alert-msg {
      border-color: $success;
    }

    .form-element__alert-icon {
      background-color: $success;
    }
  }
}
