.article-page, .content-page {
    &__hero {
        margin-top: $gap-medium;

        @include media-breakpoint-up(lg) {
            margin-top: $gap-large;
        }

        @include media-breakpoint-only(xl) {
            margin-bottom: $gap-xlarge;
        }

        @include media-breakpoint-up(xxl) {
            margin-bottom: $gap-xxxlarge;
        }
    }

    &__block {
        margin-bottom: $gap-large;

        @include media-breakpoint-up(md) {
            margin-bottom: $gap-xlarge;
        }
    }

    &__indent-text-block {
        margin-top: $gap-large;
        margin-bottom: $gap-large;

        @include media-breakpoint-up(md) {
            margin-top: $gap-xlarge;
            margin-bottom: $gap-xlarge;
        }
    }

    &__caption {
        margin-top: $gap-medium;

        @include media-breakpoint-up(md) {
            margin-top: $gap-large;
        }
    }

    &__list-box {
        @include media-breakpoint-down(sm) {
            margin-left: calc(-.5 * var(--bs-gutter-x));
            margin-right: calc(-.5 * var(--bs-gutter-x));
            padding-right: 0.75rem;
            padding-left: 0.75rem;
        }

        background-color: $light-blue;
        padding: $gap-xlarge 4.5rem;
    }

    &__list {
        padding-left: $gap-large;
    }

    &__news-label {
        margin-bottom: $gap-medium;
    }

    &__contact-info {
        @include media-breakpoint-down(md) {
            margin-top: $gap-large;
        }
    }

    &__contact-name {
        margin-bottom: $gap-medium;
        text-transform: none;
        font-size: $heading-h3-mobile;
        line-height: $line-height-h3-mobile;

        @include media-breakpoint-up(lg) {
            font-size: $heading-h3;
            line-height: $line-height-h3;
        }
    }
}
