.company-additional {

}

.company-additional__heading {
    color: #002da1;
    font-size: 24px;
    line-height: 29px;
    padding-bottom: 17px;
    border-bottom: 2px solid #002da1;
    text-transform: uppercase;
}