.regional-offering-block {

    .notexttransform {
        text-transform: none;
    }

    .title {
        @include font-primary-bold();
        color: $blue;
        text-transform: none;
    }

    .square {
        background-color: $light-blue;
        max-width: 353px;
        height: max-content;
        padding: 10px 10px 1rem;

        div {
            padding: 0 10px 20px;
        }
    }

    .square p {
        margin: 0;
        color: $black;
    }

    .square h4 {
        @include font-primary-bold();
        text-transform: none;
        margin: 10px 0 10px;
    }

    .square .btn {
        margin-top: 10px;
        padding: 9px 14px;
        border: 2px solid $blue;
        text-align: center;
        text-transform: uppercase;
        cursor: pointer;
        width: 100%;
    }

    .contact p {
        color: $black;
        font-family: finlandica-bold,sans-serif;
        font-weight: 400;
        margin-bottom: 12px;
    }

    .contact a {
        display: block;
        color: $blue !important;
    }

    .first-accordion {
        padding-bottom: 2rem;
    }

    .acc-container {
        margin-top: 32px;
        margin-bottom: 38px;
    }

    .link a {
        display: block;
        padding: 5px;
        color: $blue !important;
    }

    .acc-text h4 {
        color: $blue;
    }

    .acc-text p {
        color: $black;
    }

    .acc-text a {
        color: $blue !important;
    }

    .accordion-header {
        padding: 0;
    }

    .accordion-button {
        color: $blue;
        border: 1px solid $blue;

        :focus {
            @include focus-styles();
        }

        h3 {
            margin: 0;
            padding: 0;
        }
    }

    .accordion-body {
        color: $blue;
        border: 1px solid $blue;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    .acc-text {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    .accordion-button:not(.collapsed) {
        color: $blue;
        background-color: white !important;
    }

    .second-accordion-text p {
        margin: 0;
    }

    button.accordion-button.collapsed img {
        transform: rotate(180deg) !important;
    }

    .accordion-button:focus {
        :focus {
            @include focus-styles();
        }
    }

    @include media-breakpoint-down(sm) {

        .square {
            margin: auto;
        }

        .accordion-box {
            display: inline;
        }

        .second-accordion-text, .link, .col-4 {
            width: 100%;
            padding-bottom: 1rem;
        }

        .second-accordion-text, .acc-text {
            margin-bottom: 1rem;
        }
    }
}
