﻿.user {
    .unified-menu-desktop-user {
        display: block;
    }

    @media (max-width: 576px) {
        .unified-menu-desktop-user {
            display: none;
        }
    }
}