//This is intent = it must be out of .hero-article
.hero-article-container {
    padding-right: 0px;

    @include media-breakpoint-down(md) {
        padding-right: 12px;
    }
}

.hero-article {
  display: grid;
  grid-template-columns: 1fr;  
  grid-template-areas:
    "image"
    "label"
    "heading"
    "body"
  ;

  .hero-article__heading {
    grid-area: heading;
    text-transform: none;
    margin-bottom: $gap-large;    

    @include media-breakpoint-up(lg) {
      margin-top: $gap-xlarge;
      margin-bottom: $gap-xlarge;
    }
  }
  .hero-article__body {
    grid-area: body;
  }
  .hero-article__label {
    grid-area: label;
    color: $primary;

    @include media-breakpoint-down(md) {
      font-size: $text-4-mobile;
      line-height: $line-height-t4-mobile;
    }

    @include media-breakpoint-only(md) {
      font-size: $text-2;
      line-height: $line-height-t2;
    }
  }
  .hero-article__image {
    grid-area: image;
    margin-right: -140px;
    width: auto;

    @media (max-width: 1599.98px) { 
      margin-right: 0;
    }
  }
  .hero-article__image__description {
    @include font-primary-regular;
    font-size: 16px;
    line-height: 24px;
    word-break: break-word;
    color: $black;
    padding-top: 29.55px;

    @include media-breakpoint-down(md) {
      padding-right: 12px;
      padding-top: 16px;
      padding-bottom: 16px;
    }
  }

  @include media-breakpoint-up(md) {
    column-gap: $gap-medium;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "heading image"
      "label image"
      "body body"
    ;

    .hero-article__heading {
      grid-area: heading;
      align-self: end;
    }
    .hero-article__body {
      grid-area: body;
    }
    .hero-article__label {
      grid-area: label;
    }
    .hero-article__image {
      grid-area: image;
    }
  }

  @include media-breakpoint-between(md, xl) {
    .hero-article__body {
      margin-top: $gap-large;
    }
  }

  @include media-breakpoint-up(xl) {
    display: grid;
    column-gap: $gap-xlarge;
    grid-template-areas:
      "heading image"
      "body image"
      "label image"
    ;

    .hero-article__heading {
      text-transform: none;
      grid-area: heading;
    }
    .hero-article__body {
      grid-area: body;
      align-self: center;
    }
    .hero-article__label {
      grid-area: label;
    }
    .hero-article__image {
      grid-area: image;
    }
  }

  &--text {
    grid-template-columns: 1fr;
    grid-template-areas:
      "label"
      "heading"
      "body"
    ;

    @include media-breakpoint-up(lg) {
      grid-template-areas:
        "heading"
        "body"
        "label"
      ;
    }

    .hero-article__body {
      margin-top: 0;
    }

    .hero-article__label {
      @include media-breakpoint-down(lg) {
        font-size: $text-3;
        line-height: $line-height-t3;
      }
    }
  }
}