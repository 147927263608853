.company-slide {
    display: flex;
    flex-direction: row;
    background-color: $light-blue;

    &--bottom {
        background-color: $white;
        padding: 0 1rem;
        gap: 1rem;
    }

    h2 {
        font-size: 0.75rem;
        line-height: 100%;
        margin: 0.75rem 0 0.25rem;

        @include media-breakpoint-only(sm) {
            font-size: 0.875rem;
            line-height: 100%;
            margin: 1rem 0 0.5rem;
        }

        @include media-breakpoint-only(md) {
            font-size: 1rem;
            line-height: 1.25rem;
            margin: 1.5rem 0 0.75rem;
        }

        @include media-breakpoint-up(lg) {
            font-size: 2rem;
            line-height: 2.25rem;
            margin: 1.75rem 0 1rem;
        }
    }

    &__text-container {
        width: 55%;
        padding: 0 2%;
    }

    &__text {
        font-size: 30%;
        line-height: 100%;
        margin-bottom: 0;

        @include media-breakpoint-up(sm) {
            font-size: 40%;
            line-height: 100%;
        }

        @include media-breakpoint-up(md) {
            font-size: 50%;
            line-height: 100%;
        }

        @include media-breakpoint-up(lg) {
            font-size: 60%;
            line-height: 120%;
        }

        @include media-breakpoint-up(xl) {
            font-size: 80%;
        }
    }

    &__image-container {
        min-width: 21%;
        width: 45%;
    }

    &__image {
        display: block;
        width: 100%;
    }

    .video-file__frame {
        width: 100%;
    }
}
