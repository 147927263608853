﻿.company-search-page, .offering-page {
    // Autocomplete

    .ui-autocomplete {
        background: $white;
        color: $black;
        max-height: 350px; // Limit the height of the jquery autocomplete box
        overflow-y: auto; // Add vertical scrollbar to autocomplete automatically when needed.
        overflow-x: hidden; // Do not add horizontal scrollbar to autocomplete at all.
        padding-bottom: 5px; // Prevent scrollbar from appearing when very few items in suggestion menu eg.1 item.
    }

    strong.ui-state-highlight {
        border-width: 0;
        background-color: $light-green;
        color: $black;
    }

    .ui-state-active {
        background-color: $light-blue;
        border-width: 0;
        color: $black;
        margin: 0;
    }

    .ui-menu-item-wrapper {
        padding-left: 2.5rem;
    }

    .ui-autocomplete-category {
        font-size: 1.125rem;
        padding: 0.25rem 1.125rem 0.25rem 0;
        margin: 0 0.625rem;

        &:not(:first-child) {
            border-top: 1px solid $light-gray;
        }
    }
}
