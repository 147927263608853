.bottom-content-area {
    margin-top: 100px;
    background-color: $light-blue;
    padding: 60px 0;

    p {
        font-size: 22px;
        line-height: 27px;
        color: #000;
    }
}

.bottom-content-area__heading {
    color: $primary;
    text-transform: none;
}
