﻿.sub-offerings {
    margin-top: 80px;

    .link {
        display: flex;
        color: $primary;
        font-family: Finlandica;
        font-size: 24px;
        line-height: 50px;
        border-bottom: 1px solid #0029A880;

        &.main {
            border-bottom: 4px solid #0029A880;
            width: 23rem;
        }

        &.active {
            font-weight: bold !important;
        }

        &:hover {
            text-decoration: none;
        }
    }

    span.offering-count {
        font-weight: bold !important;
    }

    .subofferingLink {
        margin: 0 20px;
    }

    a.link.active.main {
        border-bottom: 4px solid #0029A880 !important;
    }

    #scrollbar-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 460px;
    }

    .scrollbar {
        height: 300px;
        width: 100%;
        overflow-y: auto;
        margin: 25px;
    }

    .force-overflow {
        min-height: 450px;
    }

    #scroll::-webkit-scrollbar-track {
        background-color: $light-blue;
    }

    #scroll::-webkit-scrollbar {
        width: 10px;
    }

    #scroll::-webkit-scrollbar-thumb {
        background-color: $primary;
    }

    .card {
        margin-bottom: 20px;
        position: relative;
        z-index: 0;
    }

    .card-body {
        position: relative;
        padding: 20px;
        background: #FFF;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
        z-index: 1;
    }

    @include media-breakpoint-up(lg) {
        .card-body.has-image {
            margin: -179px 28px 0px -47px;
        }

        .card-body.no-image {
            margin-top: 0;
        }
    }

    .card-title {
        font-size: 20px;
        font-weight: bold;
    }

    .card-text {
        color: $black;
    }

    .form-element__select-container {
        margin: 0;
        color: $primary;
        width: 100%;

        &::before {
            right: 15px;
        }
    }

    .dropdown-menu {
        display: none;
        position: absolute;
        z-index: 1;
        padding: 0;
        margin: 0;
        list-style: none;
        background-color: #fff;
        border: 1px solid #ccc;
        width: 100%;
    }

    .dropdown-menu li {
        padding: 5px;
    }

    .dropdown-menu li a {
        text-decoration: none;
        color: $primary;
    }

    .dropdown:hover .dropdown-menu {
        display: block;
    }

    .dropdown-menu li:hover {
        background-color: $light-blue;
    }
}
