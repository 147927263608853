@mixin font-primary-regular() {
  font-family: finlandica-regular, sans-serif !important;
  font-weight: 400 !important;
}

@mixin font-primary-bold() {
    font-family: finlandica-bold, sans-serif !important;
    // Remove font-weight: 700; to avoid bolding the text twice in firefox and safari. This makes the text look identical in chrome, safari and firefox.
}

@mixin scrollbar-blue() {
  /* Firefox */
  * {
    scrollbar-width: auto;
    scrollbar-color: $bf-blue $light-blue;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 0.875rem;
  }

  *::-webkit-scrollbar-track {
    background: $light-blue;
  }

  *::-webkit-scrollbar-thumb {
    background-color: $bf-blue;
    border-radius: 0px;
    border: 0.25rem solid $light-blue;
  }
}

@mixin skeleton-animation() {
  @keyframes placeHolderShimmer {
    0% {
      background-position: -468px 0;
    }
    100% {
      background-position: 468px 0;
    }
  }

  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f0f0f0;
  background-image: linear-gradient(
    to right,
    #f0f0f0 0%,
    #d8d8d8 20%,
    #f0f0f0 40%,
    #f0f0f0 100%
  );
  background-repeat: no-repeat;
  background-size: 800px;
}

@mixin focus-styles() {
    outline: 2px solid $primary !important;
    outline-offset: 2px !important;
}

@mixin generic-list-styles() {
    ul, ol {
        // Make nested lists possible by reverting the padding:0 change in global styles to html/css default.
        padding-left: 3rem; // 48px
    }
    // Prevent list item with paragraph starting from new line. Start it next to marker like normally in lists.
    // Seemed to be caused by tinymce surrounding the element with p-tag which is a block element. Make it inline.
    li p, ol p, ul p {
        display: inline;
    }
}
