// Form group
@include media-breakpoint-up(md) {
    .form-element__group {
        display: table;
        width: 100%;
    
        > div {
            display: table-cell;
            border-collapse: separate;
        }
    
        .form-element__input-container,
        .form-element__select-container {
            margin-top: 0;
        }
    }
}