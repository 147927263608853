.content-with-small-images-block {
    &__text {
        color: $black;
        font-size: $text-2-mobile;
        line-height: $line-height-t2-mobile;
        margin-bottom: $gap-large;

        @include media-breakpoint-up(lg) {
            font-size: $text-1;
            line-height: $line-height-t1;
        }
    }

    &__card {
        display: flex;
        margin-bottom: $gap-large;

        &:last-child {
            margin-bottom: $gap-xlarge;
        }

        @include media-breakpoint-only(md) {
            flex-direction: column;
        }

        @include media-breakpoint-up(lg) {
            flex-direction: row;
        }
        /*&--vertical {
            flex-direction: column;

            .contact-info__image-container {
                margin-right: 0;
                margin-bottom: $gap-large;
            }

            .contact-info__header {
                margin: 0;
            }

            .contact-info__name {
                margin-bottom: $gap-small;
            }
        }*/
    }

    &__image-container {
        display: none;

        @include media-breakpoint-up(md) {
            display: block;
            margin-bottom: $gap-medium;
        }

        @include media-breakpoint-up(lg) {
            margin-bottom: 0;
            margin-right: $gap-large;
        }
    }

    &__image {
        height: 170px;
        aspect-ratio: 1;

        @include media-breakpoint-up(xl) {
            height: 175px;
        }

        &--full {
            height: auto;
            width: 100%;
            aspect-ratio: unset;
        }
    }

    &__header {
        margin-top: 0;
    }

    &__title, &__name {
        margin-bottom: $gap-medium;
        @include font-primary-bold();
    }

    &__title, &__email, &__mobile {
        font-size: $text-3-mobile;
        line-height: $line-height-t3-mobile;

        @include media-breakpoint-up(lg) {
            font-size: $text-3;
            line-height: $line-height-t3;
        }
    }

    &__name {
        font-size: $heading-h4-mobile;
        line-height: $line-height-h4-mobile;

        @include media-breakpoint-up(lg) {
            font-size: $heading-h4;
            line-height: $line-height-h4-mobile;
        }
    }

    &__email, &__mobile, &__description {
        color: $primary;

        strong {
            @include font-primary-bold();
        }
    }

    &__email {
        margin-bottom: $gap-small;
    }

    &__btn-container {
        display: flex;
        justify-content: center;
    }

    &__description {
        word-break: break-word;
    }
}
