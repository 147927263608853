@font-face {
  font-family: finlandica-regular;
  src: url(../../fonts/finlandica-regular.eot);
  src: url(../../fonts/finlandica-regular.eot) format("embedded-opentype"),
    url(../../fonts/finlandica-regular.woff2) format("woff2"),
    url(../../fonts/finlandica-regular.woff) format("woff"),
    url(../../fonts/finlandica-regular.ttf) format("truetype");
}

@font-face {
  font-family: finlandica-bold;
  src: url(../../fonts/finlandica-bold.eot);
  src: url(../../fonts/finlandica-bold.eot) format("embedded-opentype"),
    url(../../fonts/finlandica-bold.woff2) format("woff2"),
    url(../../fonts/finlandica-bold.woff) format("woff"),
    url(../../fonts/finlandica-bold.ttf) format("truetype");
}

h1,
h2,
h3,
h4 {
  @include font-primary-bold();
  color: $primary;
  text-transform: uppercase;
  margin: 0 0 $gap-medium;

  @include media-breakpoint-up(lg) {
    margin: 0 0 $gap-large;
  }
}

h1 {
  font-size: $heading-h1-mobile;
  line-height: $line-height-h1-mobile;

  @include media-breakpoint-up(lg) {
    font-size: $heading-h1;
    line-height: $line-height-h1;
  }
}

h2 {
  font-size: $heading-h2-mobile;
  line-height: $line-height-h2-mobile;

  @include media-breakpoint-up(lg) {
    font-size: $heading-h2;
    line-height: $line-height-h2;
  }
}

h3 {
  font-size: $heading-h3-mobile;
  line-height: $line-height-h3-mobile;

  @include media-breakpoint-up(lg) {
    font-size: $heading-h3;
    line-height: $line-height-h3;
  }
}

h4 {
  font-size: $heading-h4-mobile;
  line-height: $line-height-h4-mobile;

  @include media-breakpoint-up(lg) {
    font-size: $heading-h4;
    line-height: $line-height-h4;
  }
}

p {
  @include font-primary-regular();
  color: $black;
  margin: 0 0 $gap-large;
  font-size: $text-2-mobile;
  line-height: $line-height-t2-mobile;

  @include media-breakpoint-up(lg) {
    font-size: $text-2;
    line-height: $line-height-t2;
  }

  &.text-t1 {
    font-size: $text-1-mobile;
    line-height: $line-height-t1-mobile;

    @include media-breakpoint-up(lg) {
      font-size: $text-1;
      line-height: $line-height-t1;
    }
  }

  &.text-t3 {
    font-size: $text-3-mobile;
    line-height: $line-height-t3-mobile;

    @include media-breakpoint-up(lg) {
      font-size: $text-3;
      line-height: $line-height-t3;
    }
  }

  &.black {
    color: $black;
  }
}

.text {
  @include font-primary-regular();
  color: $black;
  margin: 0 0 $gap-large;

  &--bold {
    @include font-primary-bold();
  }

  &--uppercase {
    text-transform: uppercase;
  }

  &--t1 {
    font-size: $text-1-mobile !important;
    line-height: $line-height-t1-mobile !important;

    @include media-breakpoint-up(lg) {
      font-size: $text-1 !important;
      line-height: $line-height-t1 !important;
    }
  }

  &--t2 {
    font-size: $text-2-mobile;
    line-height: $line-height-t2-mobile;

    @include media-breakpoint-up(lg) {
      font-size: $text-2;
      line-height: $line-height-t2;
    }
  }

  &--t3 {
    // Need to put this as important. Without it the font-size gets overriden by button or link font-sizes
    font-size: $text-3-mobile !important;
    line-height: $line-height-t3-mobile !important;

    @include media-breakpoint-up(lg) {
      font-size: $text-3 !important;
      line-height: $line-height-t3 !important;
    }
  }

  &--t4 {
    font-size: $text-4-mobile;
    line-height: $line-height-t4-mobile;

    @include media-breakpoint-up(lg) {
      font-size: $text-4;
      line-height: $line-height-t4;
    }
  }
  &--blue {
    color: $primary;
  }
}

.heading {
  @include font-primary-bold();
  color: $primary;
  text-transform: uppercase;
  margin: 0 0 $gap-medium;
  padding-top: 1rem;

  @include media-breakpoint-up(lg) {
    margin: 0 0 $gap-large;
  }

  &--h1 {
    font-size: $heading-h1-mobile;
    line-height: $line-height-h1-mobile;

    @include media-breakpoint-up(lg) {
      font-size: $heading-h1;
      line-height: $line-height-h1;
    }
  }

  &--h2 {
    font-size: $heading-h2-mobile;
    line-height: $line-height-h2-mobile;

    @include media-breakpoint-up(lg) {
      font-size: $heading-h2;
      line-height: $line-height-h2;
    }
  }

  &--h3 {
    font-size: $heading-h3-mobile;
    line-height: $line-height-h3-mobile;

    @include media-breakpoint-up(lg) {
      font-size: $heading-h3;
      line-height: $line-height-h3;
    }
  }

  &--h4 {
    font-size: $heading-h4-mobile;
    line-height: $line-height-h4-mobile;

    @include media-breakpoint-up(lg) {
      font-size: $heading-h4;
      line-height: $line-height-h4;
    }
  }

  &--no-transform {
    text-transform: none;
  }
}

.uppercase {
  text-transform: uppercase;
}

strong {
  @include font-primary-bold();
}
