img.modal-close-image-size {
    width: 40px;
}

img.modal-close-image-size-clear-chat {
    width: 25px;
}

button.modal-no-button-styles {
    background-color: transparent;
    margin: 0;
    padding: 0;
    border: none;
    display: flex;
    align-items: center;

    // Remove the hover effect on the button that triggers the modal
    // Add the hover effect to the modal close button
    &:focus, &:hover  {
        outline: 2px solid $primary;
        background-color: transparent !important; // For some reason important is needed because btn-secondary colors are overwriting it in headermo.cshtml but not in modals.
        outline-offset: 2px;
    }
}

button.modal-terms-buttons {
    min-width: 190px; // Min width specified for buttons. The accept button is too small.
}

.modal-terms-body {
    p {
        // Data comes from tinymce editor. Have to use generic tag.
        margin-bottom: 1rem;
    }
}

// This condition is here because MO terms modal allows scrolling of content when the modal is visible.
// Normally the Bootstrap modal functionality should handle this but now it fails because of custom HTML and JS.
body:has(#termsAndConditionsModalBackdrop) {
    overflow: hidden;
}