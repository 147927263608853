.list-sort-panel {
    margin-top: 25px;
}

.list-sort-panel__label {
    font-size: 20px;
    line-height: 24px;
}

.list-sort-panel__options {
    font-size: 0;
    line-height: 0;
}

ul.list-sort-panel__options {
    padding-left: 0;
}

.list-sort-panel__options-item {
    display: inline-block;
    font-size: 16px;
    line-height: 24px;
    border-left: 1px solid #002da1;
    margin-top: 10px;
    padding: 0 10px;

    &:first-child {
        border-left: none;
        padding-left: 0;
    }

    &--active {
        .list-sort-panel__options-link {
            @include font-primary-bold();
        }
    }
}

.list-sort-panel__options-link {
    color: #002da1;
    text-transform: uppercase;
    position: relative;

    &:hover,
    &:focus {
        text-decoration: none;
    }

    &--table {
        padding-left: 29px;

        &::before {
            content: '';
            display: inline-block;
            width: 24px;
            height: 24px;
            background: url(../../img/list-sort-panel/list-sort-panel-table-icon.svg) center center no-repeat;
            position: absolute;
            top: -2px;
            left: 0;
        }
    }

    &--cards {
        padding-left: 36px;

        &::before {
            content: '';
            display: inline-block;
            width: 24px;
            height: 24px;
            background: url(../../img/list-sort-panel/list-sort-panel-cards-icon.svg) center center no-repeat;
            position: absolute;
            top: -2px;
            left: 0;
        }
    }
}

@include media-breakpoint-up(lg) {
    .list-sort-panel {
        margin-top: 0;
    }
}