.short-list-company-page {
    .headline-block {
        margin-top: 30px;
    }

    .list-header {
        & > .row:first-child {
            padding-bottom: 10px;
        }

        h3.heading--h2 {
            display: none;
        }
    }

    .list-sort-panel {
        margin-top: 26.5px;
    }

    .content-wrapper {
        padding-bottom: 100px;
    }
}
