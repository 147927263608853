.content {
    &__image {
        display: block;
        aspect-ratio: 4 / 3;
        width: 100%;
        object-fit: cover;

        @include media-breakpoint-down(md) {
            padding-bottom: $gap-large-px;
        }
    }
}
