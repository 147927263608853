.content {
  &__container {
    &--horizontal {
      display: none;
      @include media-breakpoint-up(md) {
        display: block;

        .row div:has(.content__text-area) {
            display: flex;
            align-items: center;
            justify-content: center;
        }
      }
    }

    &--vertical {
      display: block;
      @include media-breakpoint-up(md) {
        display: none;
      }
    }
  }

  &__fact-container  {
    position: relative;
    display: flex;
    height: 100%;

    &__ratio-four-to-three {
        @include media-breakpoint-up(md) {
            height: 302.76px;
        }

        @include media-breakpoint-up(lg) {
            height: 342px;
        }

        @include media-breakpoint-up(xl) {
            height: 409.5px;
        }

        @include media-breakpoint-up(xxl) {
            height: 477px;
        }
    }

    &__ratio-one-to-one {
        @include media-breakpoint-up(md) {
            height: 356px;
        }

        @include media-breakpoint-up(lg) {
            height: 456px;
        }

        @include media-breakpoint-up(xl) {
            height: 546px;
        }
    }
  }

  &__bg-image-container {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}