$border-primary: 2px solid $primary;

.process-links {
  &__main-heading {
    margin-bottom: $gap-xlarge;
  }
  &__items {
    @include media-breakpoint-up(md) {
      margin-left: $gap-xlarge;
    }
  }
  &__number-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__number {
    padding: $gap-medium 1.5rem;
    margin: 0;
    background-color: $primary;
    color: $white;
  }

  &__item-heading {
    margin-bottom: $gap-medium;
    margin-right: 4rem;
  }

  &__text {
    margin-bottom: $gap-large;

    @include media-breakpoint-only(md) {
      font-size: $text-3-mobile;
      line-height: $line-height-t3-mobile;
    }

    @include media-breakpoint-up(lg) {
      margin-bottom: $gap-xlarge;
    }
    @include media-breakpoint-down(sm) {
      margin-right: 4rem;
    }
  }

  &__vertical-line {
    height: 100%;
    width: 0;
    border-left: $border-primary;
  }

  &__item {
    display: grid;
    gap: $gap-large;
    grid-template-columns: 56px minmax(250px, 630px);

    &:last-child {
      .process-links__number-container {
        justify-content: flex-start;
      }

      .process-links__text {
        margin-bottom: 0;
      }
    }
  }

  &__link {
    color: $primary;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }

    &:focus-visible {
      outline: transparent;
    }

    &:hover, &:focus-visible {
      text-decoration: none;
      border-top: $border-primary;
      border-bottom: $border-primary;
    }
  }
}