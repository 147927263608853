.offering-page {
    #trigger-container {
        display: flex;
        align-items: center;
    }

    #trigger-container {
        justify-content: end;
    }

    .content-wrapper {
        padding-bottom: 0;
    }
}

@include media-breakpoint-up(sm) {
    .offering-page {
        .hero {
            &__header-container {
                margin-top: 0;
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .offering-page {
        .hero-block {
            margin-bottom: 0;
        }

        .search-field {
            margin-top: 20px;
        }
    }
}