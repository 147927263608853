.tooltip {
    &__toggle {
        background-color: transparent;
        border: none;
        height: 2.75rem;
        width: 2.75rem;
        padding: 0.375rem 0 0.125rem;
        border-top: 2px solid transparent;
        border-bottom: 2px solid transparent;

        &:hover {
            cursor: pointer;
            border-top: $border-primary--medium;
            border-bottom: $border-primary--medium;
        }

        &:focus {
            outline: none !important;
            border-top: $border-primary--medium;
            border-bottom: $border-primary--medium;
        }
    }

    &__ai-icon {
        margin-top: -0.6rem;
    }

    &__notice {
        font-size: 10px !important;
    }

    &__tip-container {
        display: flex;
        width: 380px;
        justify-content: flex-end;
        margin-top: $gap-medium;

        @include media-breakpoint-up(lg) {
            margin-top: 0;
            justify-content: unset;
        }
    }

    &__tip {
        position: relative;
        display: flex;
        max-width: 23.75rem;
        background-color: $primary;
        color: $white;
        padding: $gap-medium;
        z-index: 150; // if tip is closed the z-index has no effect but when it is open it does have effect.
    }

    &__tip-icon-text {
        color: $primary;

        @include media-breakpoint-up(lg) {
            position: absolute;
            top: 12px;
            right: -60px;
        }
    }

    &__tip-icon-text-ai {
        color: $primary;

        @include media-breakpoint-up(lg) {
            position: absolute;
            top: 12px;
            right: -120px;
        }
    }

    &__tip-label {
        padding-right: $gap-small;
    }

    &__tip-text {
        border-left: 1px solid $white;
        padding-left: $gap-small;
    }

    &__airegenaratebutton, &__aicopybutton {
        background-color: transparent;
        border: none;
        color: #ffffff;
        text-transform: none;
        margin: 0;
        padding: 0;

        &:hover, &:focus {
            color: #ffffff !important;
            outline: 2px solid #ffffff !important;
            outline-offset: 2px !important;
        }
    }

    &__margin-top {
        margin-top: $gap-medium;

        @include media-breakpoint-up(lg) {
            margin-top: 3.5rem;
        }
    }

    &__triangle-bottom-left {
        position: absolute;
        left: 0;
        bottom: 0;
        border-bottom: 20px solid white;
        border-right: 20px solid transparent;
    }

    &__triangle-bottom-right {
        position: absolute;
        right: 0;
        bottom: 0;
        border-bottom: 20px solid white;
        border-left: 20px solid transparent;
    }

    &__triangle-top-left {
        position: absolute;
        left: 0;
        top: 0;
        border-top: 20px solid white;
        border-right: 20px solid transparent;
    }

    &__toggle-ai {
        @include media-breakpoint-down(lg) {
            position: relative;
            top: 10px;
        }
    }
}
