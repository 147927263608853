.showmore {
    display: none;
    padding: 2rem 4rem 0 3rem;

    @include media-breakpoint-up(xl) {
        padding: 2rem 4rem 0 5rem;
    }

    @media (min-width: 1600px) {
        padding: 2rem 4rem 0 9rem;
    }
}

.showmore-title {
    display: inline-block;
}

.centeringContainer {
    text-align: center;
}

.close-button {
    float: right;
    font-size: 18px;
    line-height: 30px;
    padding: 10px 28px 8px;
    background-color: transparent !important;
    color: #0029A8;
    border: 2px solid #002da1;
    text-align: center;
    display: inline-block;
}

@include media-breakpoint-down(sm) {
    .button-show {
        padding: 5px;
        
    }

    .explore {
        padding: 5px;
    }

    .close-button-mobile {
        font-size: 18px;
        line-height: 30px;
        padding: 10px 28px 8px;
        background-color: transparent !important;
        color: #0029A8;
        border: 2px solid #002da1;
    }

    .showmore {
        padding: 2rem 1rem 0 1rem;
    }

    .buttons-showmore {
        padding-right: 3px;
        display: flex;
        justify-content: space-between;
    }
}
