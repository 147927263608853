﻿#costcalculatorcontainer {
    /* This file has been taken directly from Finpro.Web solution.
       Some parts are changed but would need some detailed go through...
    */

    h2 {
        margin-bottom: 30px;
    }

    h3 {
        line-height: 1.75rem;
        margin: 1rem 0 1rem;
    }

    .form-element {
        margin-bottom: 0.5rem;
    }

    .moreinfo {
        margin-left: 1.5rem;
        margin-bottom: 0.5rem;
        line-height: 1.85rem;
    }

    .employee-type, .monthly-income {
        display: flex;

        label {
            margin-top: 13px;
        }

        .employee-type-name {
            span {
                text-transform: capitalize;
                font-weight: bold;
            }
        }
    }

    #income {
        background-color: #f5f5f5;
        border: 0 solid transparent;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        -webkit-transition: all .2s ease-in-out;
        transition: all .2s ease-in-out;
    }

    label.bold {
        font-weight: bold;
    }

    span.cost-calculation {
        border-bottom: 1px dotted $primary;
        width: 40%;
        display: inline-block;
    }

    #employee-receives {
        margin-bottom: 30px;
    }

    #employee-receives, #employer-cost-1 {
        background-color: #f5f5f5;
        width: 90px;
        padding: 10px;
        text-align: center;
    }

    #employer-cost-1 {
        margin-bottom: 30px;
    }
}

// Mobile styles
@media (max-width: 1240px) {
    #costcalculatorcontainer {
        padding: 0 20px;
    }
}

@media (max-width: 1100px) {
    #costcalculatorcontainer {
        .employee-type, .monthly-income {
            .input-income-box {
                width: 60%;
            }
        }
    }
}

@media (max-width: 740px) {
    #costcalculatorcontainer {
        .employee-type, .monthly-income {
            display: block;

            select, #income {
                margin-left: 0;
            }
        }
    }
}

@include media-breakpoint-down(md) {
    #costcalculatorcontainer span.cost-calculation {
        width: 90%;
    }
}