.form-progress {
    margin-top: 30px;
    overflow: hidden;
}

.form-progress__step {
    display: inline-block;
    float: left;
    color: $black;
    line-height: 42px;
    padding-bottom: 21px;
    position: relative;
}

.form-progress__link {
    color: $black;
    text-decoration: none;

    &:hover {
        text-decoration: none;
    }
}
                            
.form-progress__number {
    @include font-primary-bold();
    float: left;
    width: 42px;
    height: 42px;
    font-size: 28px;
    line-height: 42px;
    text-align: center;
    border: 1px solid $primary;
    border-radius: 21px;
    color: $primary;
    margin-right: 10px;
}

.form-progress__label {
    line-height: 21px;
    display: inline-block;
    vertical-align: middle;
}
                            
.form-progress__additional-info {
    position: absolute;
    bottom: 0;
    left: 52px;
    font-size: 12px;
    line-height: 21px;
}

.form-progress__step--active {
    .form-progress__number {
        background-color: $primary;
        color: $white;
    }
}

@include media-breakpoint-down(lg) {
    .form-progress__step {
        width: 50%;
        
        &:nth-child(n + 3) {
            margin-top: 9px;
        }
    }
}

@include media-breakpoint-up(lg) {
    .form-progress__step {
        width: 150px;
    }
}

@include media-breakpoint-up(xl) {
    .form-progress__step {
        width: 200px;
    }
}
