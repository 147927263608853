.topic-links {
  &__main-heading {
    margin-bottom: $gap-large;
  }

  &__top-container {
    width: 100%;

    @include media-breakpoint-only(md) {
      width: 87%;
    }

    @include media-breakpoint-only(lg) {
      width: 83%;
    }

    @include media-breakpoint-up(xl) {
      width: 67%;
    }
  }

  &__image {
    display: block;
    width: 100%;

    &--main {
      margin-bottom: $gap-medium;

      @include media-breakpoint-up(lg) {
        margin-bottom: $gap-large;
      }
    }

    &--small {
      width: 50%;

      @include media-breakpoint-down(md) {
        display: none;
      }

      @include media-breakpoint-only(md) {
        margin-bottom: $gap-medium;
      }

      @include media-breakpoint-up(lg) {
        width: 100%;
      }
    }
  }

  &__main-card {
    @include media-breakpoint-down(md) {
      margin-bottom: $gap-xlarge;
    }
  }

  &__cards-col-2 {
    display: grid;
    grid-template-columns: 1fr;

    @include media-breakpoint-up(md) {
      grid-template-columns: 1fr 1fr;
      column-gap: 1rem;
    }
  }

  &__card {
    &:not(:last-child, .topic-links__card--in-cols) {
      margin-bottom: $gap-xlarge;

      @include media-breakpoint-up(lg) {
        margin-bottom: $gap-large;
      }
    }

    @include media-breakpoint-up(lg) {
      display: grid;
      grid-template-columns: 1fr 2fr;
      column-gap: $gap-medium;
    }

    &--in-cols {
      margin-bottom: $gap-xlarge;
    }
  }

  &__label {
    margin-bottom: $gap-medium;

    @include media-breakpoint-down(md) {
      font-size: $text-3;
      line-height: $line-height-t3;
    }

    &--main {
      @include media-breakpoint-up(lg) {
        margin-bottom: $gap-large;
      }
    }

    &--bold {
      @include media-breakpoint-down(md) {
        @include font-primary-bold();
      }
    }
  }

  &__heading {
    margin-bottom: $gap-medium;
  }

  &__text {
    @include media-breakpoint-down(md) {
      font-size: $text-3;
      line-height: $line-height-t3;
    }
  }

  &__distance {
    margin-top: $gap-medium; 
  }

  &__button-container {
    display: flex;
    justify-content: center;
    margin-top: $gap-xlarge;
  }

  .btn {
    font-size: $text-3;
    line-height: $line-height-t3;
  }
}