.cta-card {
  display: flex;
  flex-direction: column;

  @include media-breakpoint-up(md) {
    flex-direction: row;
  }

  &__image-container {
    width: 100%;
    margin-bottom: $gap-large;

    @include media-breakpoint-up(md) {
      width: 25%;
    }
  }

  &__image {
    display: block;
    width: 100%;
    aspect-ratio: 1;
    object-fit: cover;

    @include media-breakpoint-up(md) {
      max-width: 225px;
    }
  }

  &__content {
    width: 100%;

    @include media-breakpoint-up(md) {
      width: 75%;
      padding-left: 8%;
    }
  }

  &__header {
    font-size: $heading-h4-mobile;
    line-height: $line-height-h4-mobile;
    margin-bottom: $gap-medium;

    @include media-breakpoint-up(lg) {
      font-size: $heading-h4;
      line-height: $line-height-h4;
    }
  }

  &__text {
    font-size: $text-2-mobile;
    line-height: $line-height-t2-mobile;
    color: $black;
    margin-bottom: $gap-large;

    @include media-breakpoint-up(lg) {
      font-size: $text-2;
      line-height: $line-height-t2
    }
  }
}