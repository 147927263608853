.headline-block__headline {
    font-size: 48px;
    line-height: 52px;
    padding-right: 43px;
    margin: 0;
    position: relative;
    display: inline-block;

    &::before {
        content: '';
        position: absolute;
        top: 8px;
        bottom: 8px;
        right: 0;
        width: 1px;
        background-color: #002ea2;
    }
}

.headline-block__description {
    margin-top: 20px;

    p {
        font-size: 22px;
        line-height: 26.4px;
        color: $black;
    }
}

@include media-breakpoint-up(lg) {
    .headline-block {
        overflow: hidden;
    }

    .headline-block__headline {
        float: left;
        padding-right: 35px;
    }
    
    .headline-block__description {
        display: block;
        overflow: hidden;
        padding-left: 35px;
        margin: 4px 0 0 0;
    }
}
