﻿.company-form-page .page-content {
    h1, h2, h3, h4 {
        @include font-primary-bold();
        color: $primary;
        text-transform: uppercase;
    }

    h1 {
        font-size: $heading-fs-h1;
        line-height: $line-height-fs-h1;
        margin: $gap-large 0;
    }

    h2 {
        font-size: $heading-fs-h2;
        line-height: $line-height-fs-h2;
        margin: $gap-large 0 $gap-medium;
    }

    h3 {
        font-size: $heading-fs-h3;
        line-height: $line-height-fs-h3;
        margin: $gap-medium 0 $gap-medium;
    }

    h4 {
        font-size: $heading-fs-h4;
        line-height: $line-height-fs-h4;
        margin: $gap-medium 0 $gap-medium;
    }

    p {
        @include font-primary-regular();
        font-size: $text-fs-1;
        line-height: $line-height-fs-t1;
        color: $black;
        margin-bottom: $gap-medium;
    }

    .sub {
        margin-left: 33px;
    }
}
