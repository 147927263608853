.content {
  &__type {
    font-size: $text-1;
    line-height: $line-height-t1;
    color: $black;
    text-transform: uppercase;

    &--highlight, &--articles, &--startup-steps {
      display: block;
      margin-bottom: 16px;
      font-size: 22px;
      line-height: 30px;

      @include media-breakpoint-up(lg) {
        font-size: 20px;
        line-height: 28px;
      }
    }
  }

  &__text {
    font-size: $text-2-mobile;
    line-height: $line-height-h3-mobile;
    margin-bottom: $gap-large-px;
    color: $black;

    &--content-with-image {
      font-size: $text-3-mobile;
      line-height: $line-height-t3-mobile;

      @include media-breakpoint-up(lg) {
        font-size: $text-2;
        line-height: $line-height-t2;
        margin: 0 0 $gap-large-px;
      }
    }

    &--highlight, &--articles {
      @include media-breakpoint-up(lg) {
        font-size: $text-2;
        line-height: $line-height-t2;
      }
    }

    &--startup-steps {
      font-size: $text-1-mobile;
      line-height: $line-height-t1-mobile;

      @include media-breakpoint-up(lg) {
        margin-bottom: $gap-xlarge;
      }
    }
  }
}