﻿.contact-container {
    .contact-title,
    .contact-name,
    .contact-industries,
    .contact-area {
        margin: 0 0 $gap-small;
    }

    .contact-email,
    .contact-number {
        margin: 0 0 $gap-medium;
    }

    .contactme-btn {
        margin-top: $gap-small;
    }

    .contact-title,
    .contact-name,
    .contact-email,
    .contact-number {
        color: $primary;
        display: block;
    }

    .contact-title {
        text-transform: uppercase;
        @include font-primary-bold();
    }

    .contact-industries {
        position: relative;
        padding-right: 60px;

        .showMore-container {
            &.showMore-collapsed {
                display: block;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }

            a.showMore {
                color: $primary;
                display: inline-block;
                position: absolute;
                right: 0;
                bottom: 0;
                background: white;
                text-align: right;
                font-weight: 700 !important;
            }
        }
    }

    .contact-modal-content-background {
        background-color: $light-blue;
    }

    .contact-modal-header {
        border: none;
        margin: $gap-large;
        padding: 0;
    }

    .contact-modal-title {
        margin: 0;
        padding: 0;
        text-transform: none;
    }

    .contact-modal-body {
        padding: 0;
        margin: 0 $gap-large $gap-xlarge $gap-large;
    }
}
