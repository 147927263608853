.short-list-export-page, .export {
  h2 {
    margin-bottom: $gap-large;
  }

  .form-element__checkbox {
    margin-left: 3px;
  }

  .form-element__input-container, .form-element__textarea-container {
    margin-right: 0;
  }

  .form-element__textarea-container {
    margin-bottom: $gap-large;
  }

  .short-list-export-style {
    margin-bottom: $gap-large;

    .form-element {
      margin: 0;
    }
  }

  .tailored-export-list-options {
    margin-bottom: $gap-large;

    .form-element {
      width: 50%;

      @include media-breakpoint-only(md) {
        width: 33.3%;
      }

      @include media-breakpoint-up(lg) {
        width: 25%;
      }
    }
  }

  .export-link, .form-element__textarea, .form-element__radio-label {
    font-size: $text-3-mobile;
    line-height: 1.35rem;
  }

  .export-link {
    background-color: #EDEDED;
    color: $primary;
    padding: 1.25rem;
  }
}

.export {
    margin-bottom: 100px;

    .headline-block {
        margin: 30px 0;
    }

    h3 {
        text-transform: none;
    }

    a:hover {
        cursor: pointer;
    }

    .form-element__alert-container {
        margin-right: 0;
    }
}
