// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "bootstrap/scss/functions";

// 2. Include any default variable overrides here
$primary: #002da1;
$danger: #d4564a;
$success: #00814b;
$blue: #002ea2;
$modal-backdrop-bg: #ffffff;
$modal-backdrop-opacity: 0.8;

// 3. Include remainder of required Bootstrap stylesheets

@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/root";
// @import "bootstrap/scss/maps";

// 4. Include any optional Bootstrap CSS as needed
@import "bootstrap/scss/utilities";
//@import "bootstrap/scss/reboot";
//@import "bootstrap/scss/type";
//@import "bootstrap/scss/images";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/helpers";
@import "bootstrap/scss/transitions";
@import "bootstrap/scss/modal";
@import "bootstrap/scss/accordion";
@import "bootstrap/scss/carousel";
@import "bootstrap/scss/dropdown";
@import "bootstrap/scss/navbar";

// 5. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import "bootstrap/scss/utilities/api";

// 6. Add additional custom code here
