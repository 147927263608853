// Textarea
.form-element__textarea-container {
  position: relative;
  background-color: #f5f5f5;
  margin-right: 36px;

  :has(textarea:focus) {
      @include focus-styles();
  }
}

.form-element__textarea {
  @include font-primary-regular();
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  resize: none;
}

.form-element__textarea_with_delete {
    @include font-primary-regular();
    width: 100%;
    box-sizing: border-box;
    margin: 0;
    resize: none;
    padding-right: 4.5rem !important;
    padding-top: 50px !important;
}

.form-element__textarea-counter {
  @include font-primary-regular();
  font-size: 11px;
  line-height: 13px;
  position: absolute;
  top: 12px;
  right: 20px;
  color: $primary;
}

.form-element__textarea-delete {
  width: 22px;
  height: 27px;
  background: url(../../../img/form/delete-icon.svg) center center no-repeat;
  position: absolute;
  top: 36px;
  right: 0px;
  cursor: pointer;
  border: none;
}
