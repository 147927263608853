.company-gallery__big-photo {
    width: 100%;
    height: 600px;
    object-fit: contain;
    display: inline-block;
}

.company-gallery__thumbs {
    margin-top: $gap-medium;
    margin-bottom: $gap-large;
}

.company-gallery__thumb {    
    height: 180px;
    overflow: hidden;
    object-fit: cover;
}

@include media-breakpoint-down(md) {
    .company-gallery__big-photo {
        height: 280px;
    }

    .company-gallery__thumb {
        height: 114px;
    }
}
