.offering-collections {
    margin-top: 80px;
}

.offering-collections__heading {
    @include font-primary-regular();
    font-size: 40px;
    line-height: 48px;
    color: $primary;
    margin: 0;
    text-transform: uppercase;
    /*&--bold*/
    strong {
        @include font-primary-bold();
    }
}

.offering-collections__description {
    margin: 17px 0 31px;

    p {
        font-size: 22px;
        line-height: 26px;
    }
}

.offering-collections__link {
    border: 1px solid #c9c9c9;
    border-top: none;
    display: flex;
    line-height: 19px;
    font-size: 16px;
    padding: 10px 46px 10px 20px;
    text-transform: uppercase;
    color: $black;
    position: relative;
    min-height: 60px;
    align-items: center;

    &::after {
        content: '';
        display: inline-block;
        position: absolute;
        right: 10px;
        top: 17px;
        width: 26px;
        height: 26px;
        transform: translateX(0);
        transition: .25s;
        background: url(../../img/offering-collections/offering-collections-link-icon.svg) center center no-repeat;
    }

    &:hover {
        color: $primary;
        text-decoration: underline;

        &::after {
            transform: translateX(50%);
            transition: all 0.25s ease 0s;
        }
    }
}


@include media-breakpoint-down(lg) {
    .offering-collections__links {
        border-top: 1px solid #c9c9c9;
    }
}

@include media-breakpoint-up(lg) {
    .offering-collections {
        margin-top: 60px;
    }

    .offering-collections__description {
        margin: 22px 0 34px;
    }

    .offering-collections__link {
        padding-right: 60px;

        &::after {
            right: 24px;
        }

        &:first-child {
            border-top: 1px solid #c9c9c9;
        }
    }
}
