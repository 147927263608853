.form-settings {
    margin-top: 46px;
}

.form-settings__item {
    display: inline-block;
    font-size: 14px;
    line-height: 28px;
}

.form-settings__link {
    color: $black;
}

@include media-breakpoint-up(md) {
    .form-settings {
        margin-top: 90px;
    }
}
