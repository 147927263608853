.services-overview {
    background-color: $light-blue;
    padding: $gap-xlarge 0;

    &__container {
        background-size: 300px;
        background-position: 50% 25px;
        background-repeat: no-repeat;

        @include media-breakpoint-up(md) {
            background-position: 12px 25px;
        }

        @include media-breakpoint-up(lg) {
            background-size: 525px;
            background-position: 12px 30px;
        }
    }

    &__card-container {
        margin: 1px 0;
        @include media-breakpoint-up(xl) {
            padding-right: 8.3333%;
        }
    }
}