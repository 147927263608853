.form-element__select-container {
  position: relative;
  background-color: #f5f5f5;
  margin-right: 36px;

  &::before {
    content: "";
    width: 30px;
    height: 30px;
    display: block;
    position: absolute;
    top: 15px;
    right: 20px;
    background: url(../../../img/form/select-icon.svg) center center no-repeat;
    cursor: pointer;
    z-index: 0;
  }
}

.form-element__select {
  @include font-primary-regular();
  background-color: transparent;
  color: $primary;
  padding: 12px 20px;
  font-size: 18px;
  line-height: 36px;
  border: none;
  width: 100%;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
  appearance: none;
  padding-right: 70px;
  cursor: pointer;
  position: relative;
  z-index: 10;

  &:focus {
    outline: 2px solid $primary;
    outline-offset: 3px;
  }

  &--white {
    background-color: $white;
    border: 1px solid $primary;
  }

  &--image {
    background-image: url(../../../img/form/arrow-down.svg);
    background-repeat: no-repeat;
    background-size: 24px;
    background-position: right 1rem center;
  }
}
