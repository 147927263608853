.link-card {
    display: block;
    color: $primary;

    @include media-breakpoint-only(md) {
        &:not(:first-child) {
            margin-top: $gap-large;
        }
    }

    @include media-breakpoint-up(lg) {
        display: flex;
        padding: 1.875rem 0;
        border-top: 2px solid transparent;
        border-bottom: 2px solid transparent;
    }

    &--imageless {
        &.external-link svg{
            display: none;
        }

        @include media-breakpoint-up(lg) {
            padding: 0.875rem 0;
        }
    }

    &__image {
        object-fit: cover; // temporary fix for images
        height: 170px;
        width: 170px;
        margin-bottom: 7px;

        @include media-breakpoint-up(lg) {
            width: 140px;
            height: 140px;
            margin: 0 $gap-large 0 0;
        }
    }

    &__text-container {
        padding: 0.75rem 0;
        border-top: 2px solid transparent;
        border-bottom: 2px solid transparent;

        @include media-breakpoint-up(md) {
            padding: 7px 0;
        }

        @include media-breakpoint-up(lg) {
            padding: 0;
            border: none;
        }
    }

    &__header {
        margin: 0;
        font-size: $heading-h4-mobile;
        line-height: $line-height-h4-mobile;

        @include media-breakpoint-up(lg) {
            font-size: $heading-h4;
            line-height: $line-height-h4;
        }

        &--services-overview {
            margin-bottom: $gap-medium;
        }
    }

    &__text {
        font-size: $text-2-mobile;
        line-height: $line-height-t2-mobile;
        color: $black;
        margin: 0;

        @include media-breakpoint-up(lg) {
            font-size: $text-2;
            line-height: $line-height-t2
        }
    }

    &:hover {
        text-decoration: none;
    }

    &:focus {
        outline: 2px solid transparent;
    }

    &:focus, &:hover {
        .link-card__text-container {
            // On mobile and tablet sizes only text elements have visual indicators for hover and focus.
            border-top: 2px solid currentColor;
            border-bottom: 2px solid currentColor;

            @include media-breakpoint-up(lg) {
                border: none;
            }
        }

        @include media-breakpoint-up(lg) {
            // On larger screens also image is part of block that has visual indicators.
            border-top: 2px solid currentColor;
            border-bottom: 2px solid currentColor;
        }
    }
}
