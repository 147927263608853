//Added btn style for all button elements - Jonathan said it is ok
.btn,
button {
  position: relative;
  font-size: 1.125rem; // 18px
  line-height: 1.875; // 30px
  padding: 14px 32px 12px;
  background-color: $primary;
  color: $white;
  border: 2px solid $primary;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  display: inline-block;
  transition: background-color 0.15s ease-in-out 0s,
    border-color 0.15s ease-in-out 0s, color 0.15s ease-in-out 0s;

  @media (prefers-reduced-motion: reduce) {
    transition: none;
  }

  &--full-width {
    width: 100%;
  }

  &:hover,
  &:focus {
    text-decoration: none;
    background-color: transparent;
    color: $primary;
  }

  &:focus {
    @include focus-styles();
  }

  &.disabled {
    cursor: default;
    background-color: $light-blue;
    color: #002ea2;
    border-color: $light-blue;
  }

  svg {
    width: 1.5rem;
    height: 1.5rem;
    vertical-align: text-bottom;
  }
}

.btn--icon {
  background-color: transparent;
  color: $primary;

  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  aspect-ratio: 1/1;
  border-color: transparent;

  svg {
    width: 1.25rem;
    height: 1.25rem;
  }

  &:hover:not(.disabled),
  &:focus:not(.disabled) {
    background-color: $light-blue;
    border-color: $light-blue;
  }
}

.btn--primary {
  background-color: $primary;
  color: $white;

  &:hover:not(.disabled) {
    background-color: $white;
    color: $primary;

    .btn__icon--plus {
      background: url(../../img/button/plus-secondary-icon.svg) center center
        no-repeat;
    }

    .btn__icon--minus {
      background: url(../../img/button/minus-secondary-icon.svg) center center
        no-repeat;
    }
  }
}

.btn--secondary {
  background-color: $white;
  color: $primary;

  &:hover:not(.disabled),
  &:focus:not(.disabled) {
    background-color: $primary;
    color: $white;

    .btn__icon--plus-secondary {
      background: url(../../img/button/plus-icon.svg) center center no-repeat;
    }

    .btn__icon--minus-secondary {
      background: url(../../img/button/minus-icon.svg) center center no-repeat;
    }
  }
}

.btn--ghost {
  background-color: transparent;
  color: $primary;
  border: none;

  &:hover:not(.disabled),
  &:focus:not(.disabled) {
    background-color: $primary;
    color: $white;
  }
}

.btn--ghost-link {
  background-color: transparent;
  color: $primary;
  border: none;
  padding: 0;
  text-transform: none;

  @include font-primary-bold();
  font-size: $text-2-mobile;
  line-height: $line-height-t2-mobile;

  @include media-breakpoint-up(lg) {
    font-size: $text-2;
    line-height: $line-height-t2;
  }

  &:hover:not(.disabled),
  &:focus:not(.disabled) {
    text-decoration: underline;
  }

  &.disabled {
    opacity: 0.5;
    background-color: transparent;
    pointer-events: none;
  }
}

.btn__icon {
  display: inline-block;
  vertical-align: middle;

  &--plus {
    width: 30px;
    height: 30px;
    background: url(../../img/button/plus-icon.svg) center center no-repeat;
    position: relative;
    top: -2px;
    margin-right: 10px;
  }

  &--plus-secondary {
    width: 30px;
    height: 30px;
    background: url(../../img/button/plus-secondary-icon.svg) center center
      no-repeat;
    position: relative;
    top: -2px;
  }

  &--minus {
    width: 30px;
    height: 30px;
    background: url(../../img/button/minus-icon.svg) center center no-repeat;
    position: relative;
    top: -2px;
    margin-right: 10px;
  }

  &--minus-secondary {
    width: 30px;
    height: 30px;
    background: url(../../img/button/minus-secondary-icon.svg) center center
      no-repeat;
    position: relative;
    top: -2px;
  }

  &--search {
    width: 19px;
    height: 18px;
    background: url(../../img/button/search-icon.svg) center center no-repeat;
    position: relative;
    top: -3px;
    margin-right: 5px;
  }
}

.btn--no-styles {
    background-color: transparent;
    padding: 0;
    margin: 0;
    border: none;
    outline: none;
    text-transform: none;
    text-align: unset;
}
