.content {
  &__header {
    font-size: $heading-h3-mobile;
    line-height: $line-height-h3-mobile;
    margin: $gap-medium-px 0;

    @include media-breakpoint-up(lg) {
      font-size: $heading-h3;
      line-height: $line-height-h3;
      margin: $gap-large-px 0;
    }

    &--highlight,
    &--startup-steps,
    &--services-overview,
    &--articles,
    &--contact-info,
    &--content-with-small-images {
      margin: 0 0 $gap-large;
    }
  }
}