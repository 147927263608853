$border-transparent: 2px solid transparent;
$border-primary: 2px solid $primary;

.news {
    &__text-container {
        border-top: $border-transparent;
        border-bottom: $border-transparent;
    }

    &__item {
        display: block;

        &:focus {
            outline: 2px solid transparent;
        }

        &:hover {
            text-decoration: none;
        }

        &:focus, &:hover {
            .news__text-container {
                border-top: $border-primary;
                border-bottom: $border-primary;
            }
        }
    }

    &__main-heading {
        margin-bottom: 1.5rem;

        @include media-breakpoint-up(md) {
            margin-bottom: $gap-large;
        }
    }

    &__image {
        aspect-ratio: 384/266; // temporary fix for images
        object-fit: cover; // temporary fix for images
        display: block;
        width: 100%;

        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    &__text-container {
        margin-bottom: 1.5rem;
        padding-top: $gap-small;

        @include media-breakpoint-only(md) {
            margin-top: $gap-small;
        }

        @include media-breakpoint-up(lg) {
            margin-top: 1.5rem;
        }
    }

    &__info-label {
        margin-bottom: $gap-medium;

        @include media-breakpoint-up(lg) {
            margin-bottom: $gap-large;
        }
    }

    &__ingress {
        margin-bottom: $gap-small;

        @include media-breakpoint-only(md) {
            font-size: $text-3-mobile;
            line-height: $line-height-t3-mobile;
        }
    }
}