.iframe-block {
    .iframe-box {
        position: relative;
        width: 100%;
        padding-top: 56.25%;
        background-color: #ececec;
    }

    .iframe-content {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
    }
}