.highlights {
  &__image {
    margin-bottom: $gap-medium;
  }

  &__buttons {
    .btn:first-child {
      margin-right: $gap-large;
    }

    .btn {
      font-size: $text-3;
      line-height: $line-height-t3;
    }
  }

  &__cards {
    @include media-breakpoint-down(sm) {
      .link-card:not(:first-child) {
        margin-top: 4px;
      }
    }

    @include media-breakpoint-up(lg) {
      margin-top: -2rem;
    }

    @include media-breakpoint-up(xl) {
      padding-left: 52px;
    }
  }
}