.navigation {
  height: 100px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    "logo actions"
  ;  

  @include media-breakpoint-up(lg) {
    grid-template-columns: min-content 1fr min-content;
    grid-template-areas:
      "logo ... secondary"
      "logo main actions"
    ;
  }

  &--mobile {
    width: 100%;
    position: absolute;
    background-color: $white;
    max-height: 0;
    transition: max-height 0.3s ease-in-out;
    overflow: hidden;
    
    @media (prefers-reduced-motion: reduce) {
      transition: none;
    }

    .navigation__link {
      display: block;
      font-size: $text-1-mobile;
      line-height: $line-height-t1-mobile;
      margin-bottom: 1.5rem;
    }
  }

  &--active {
    max-height: 1500px;
    transition: max-height 0.3s ease-in-out;
    z-index: 1;

    @media (prefers-reduced-motion: reduce) {
      transition: none;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    list-style-type: none;

    @include media-breakpoint-up(lg) {
      justify-content: center;
      flex-direction: row;
      gap: $gap-large;
    }
  }

  &__logo {
    grid-area: logo;
    margin: 0;
    align-self: center;
    margin-right: 50px;
  }

  &__main {
    display: none;

    @include media-breakpoint-up(lg) {
      grid-area: main;
      align-self: center;
      display: block;
    }

    &--mobile {
      display: block;
      margin-top: $gap-medium;

      @include media-breakpoint-up(lg) {
        display: none;
      }
    }
  }

  &__secondary {
    display: none;

    @include media-breakpoint-up(lg) {
      grid-area: secondary;
      align-self: center;
      justify-self: end;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: $gap-medium;
    }

    &--mobile {
      display: block;
      margin-top: $gap-large;

      @include media-breakpoint-up(lg) {
        display: none;
      }
    }
  }

  &__actions-container {
    display: flex;
    flex-direction: row;
    gap: $gap-large - 0.25rem;
    grid-area: actions;
    align-self: center;
    justify-self: end;
  }

  &__actions {
    display: flex;
    flex-direction: row;
    gap: $gap-large - 0.25rem;
  }

  &__vertical-separator {
    height: 1.4375rem;
    width: 0.125rem;
    background-color: $primary;
  }

  &__nav-toggle {
    display: block;
    border: none;
    background-color: transparent;

    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  &__link {
    border-top: $border-transparent--thin;
    border-bottom: $border-transparent--thin;
    font-size: $text-2;
    line-height: $line-height-t2;
    color: $primary;
    padding: 0.375rem 0;

    &--small {
      font-size: $text-3;
      line-height: $line-height-t3;
    }

    &:hover {
      text-decoration: none;
    }

    &:focus {
      outline: transparent;
    }

    &:hover, &:focus {
      border-top: $border-primary--thin;
      border-bottom: $border-primary--thin;
    }

    &[aria-current="page"] {
      @include font-primary-bold;
    }
  }

  &__nav-toggle {
    border-top: $border-transparent--thin;
    border-bottom: $border-transparent--thin;
    display: flex;
    align-items: center;
    justify-content: center;
    width: $nav-icon-container-size;
    height: $nav-icon-container-size;
    padding: 0.125rem 0 0;

    &:hover {
      cursor: pointer;
    }
    &:focus {
      outline: transparent;
    }
    &:hover, &:focus {
      border-top: $border-primary--thin;
      border-bottom: $border-primary--thin;
    }

    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  &__nav-toggle-icon {
    height: $nav-icon-size;
    width: $nav-icon-size;
  }

  &__icon-link {
    width: $nav-icon-container-size;
    height: $nav-icon-container-size;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: $border-transparent--thin;
    border-bottom: $border-transparent--thin;

    &:focus {
      outline: transparent;
    }
    &:hover, &:focus {
      border-top: $border-primary--thin;
      border-bottom: $border-primary--thin;
    }
  }

  &__icon {
    height: $nav-icon-size;
    width: $nav-icon-size;
  }
}