
.hero {
    &__image-container {
        position: relative;
    }

    &__video-container {
        height: 500px;
        width: 100%;
        object-fit: cover;
    }

    &__video-container-contain {
        height: 500px;
        width: 100%;
        object-fit: contain !important; // This is needed so it actually takes into effect because the vjs-tech class overrules this one.
    }
    /*For some reason video.js removes all the classes from the video and only keeps vjs tech class
    This is why we need to duplicate the video-container class*/
    .vjs-tech {
        height: 500px;
        width: 100%;
        object-fit: cover;
    }

    &__image {
        width: 100%;
        aspect-ratio: 390/500; // temporary fix for images (mobile aspect ratio)
        object-fit: cover; // temporary fix for images

        &--xs {
            display: block;
        }

        &--sm, &--md, &--lg {
            display: none;
        }

        @include media-breakpoint-up(sm) {
            &--sm {
                display: block;
            }

            &--xs, &--md, &--lg {
                display: none;
            }
        }

        @include media-breakpoint-up(md) {
            &--md {
                display: block;
            }

            &--xs, &--sm, &--lg {
                display: none;
            }
        }

        @include media-breakpoint-up(lg) {
            &--lg {
                display: block;
            }

            &--xs, &--sm, &--md {
                display: none;
            }
        }
    }
}

@include media-breakpoint-up(sm) {
    .hero {
        &__image {
            aspect-ratio: 1440/656; // temporary fix for images (desktop aspect ratio)
        }
    }
}