.hero {
  &__info {
    color: $black;
    margin: 1rem 0;
    width: 100%;
    font-size: $text-2-mobile;
    line-height: $line-height-t2-mobile;

    p {
        color: inherit;
        font-size: inherit;
        line-height: inherit;
    }

    @include media-breakpoint-up(sm) {
      color: $white;
      max-width: 45rem;
      font-size: $text-3;
      line-height: $line-height-t4;
    }

    @include media-breakpoint-up(md) {
      line-height: $line-height-t3;
    }

    @include media-breakpoint-up(lg) {
      margin: $gap-large 0;
    }
  }
}
