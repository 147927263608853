﻿.division-block, .content-with-three-columns-block {
    .video-container {
        height: 100%;
        width: 100%;
    }

    #youtubeVideo {
        height: 350px;

        @include media-breakpoint-down(lg) {
            height: 200px;
        }
    }

    .video-container-icarus {
        height: 340px;
        width: 100%;
    }

    .dark-blue {
        background-color: #000068;
    }

    .light-blue {
        background-color: #c2efff;
    }

    .electric-blue {
        background-color: #0048FF;
    }

    .pink {
        background-color: #d80c8c;
    }

    .light-pink {
        background-color: rgba(217, 0, 134, 0.5);
    }

    .sky-blue {
        background-color: #00afe6;
    }

    .light-sky-blue {
        background-color: #ace0f0;
    }

    .black {
        background-color: #000000;
    }

    .light-red {
        background-color: #ffd3d5;
    }

    .light-yellow {
        background-color: #fff5c7;
    }
}
