﻿.fs-main-menu {
    @include font-primary-regular();
    display: inline-block;
    font-size: 0;
    float: right;
    margin-top: 34px;

    .main-menu__item {
        display: inline-block;
        line-height: 16px;
        margin-left: 25px;

        &:first-child {
            margin-left: 0;
        }

        &--icon {
            vertical-align: bottom;

            img {
                height: 16px;
                width: auto;
            }
        }

        .navigation__nav-toggle {
            &:focus, &:hover {
                border: none;
            }
        }
    }

    .main-menu__link {
        color: $primary;
        text-decoration: none;
    }
}

@include media-breakpoint-up(lg) {
    .fs-main-menu {
        margin-top: 51px;

        .main-menu__item {
            font-size: 20px;
            line-height: 28px;
            margin-left: 20px;

            &:first-child {
                margin-left: 0;
            }

            &--text + &--icon {
                margin-left: 20px;
            }

            &--icon {
                img {
                    height: 20px;
                }
            }
        }
    }
}

@include media-breakpoint-up(xl) {
    .fs-main-menu {
        .main-menu__item {
            margin-left: 33px;

            &:first-child {
                margin-left: 0;
            }
        }
        
        .main-menu__item--text + .main-menu__item--icon {
            margin-left: 120px;
        }
    }
}
