.hero {
    &__header-container {
        display: flex;
        flex-direction: column;
        width: 100%;

        @include media-breakpoint-up(sm) {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    &__header {
        @include font-primary-regular();
        color: $primary;
        font-size: $heading-hero-mobile;
        line-height: $line-height-hero-mobile;
        text-transform: none;
        margin: 0;
        margin-top: 32px;
        max-width: 100%;

        h1, p {
            @include font-primary-regular();
            font-size: inherit;
            line-height: inherit;
            text-transform: inherit;
            margin: 0;
            max-width: 100%;
            /*color: $white;*/
            color: inherit;
        }

        @include media-breakpoint-up(sm) {
            color: $white;
            font-size: 2.75rem;
            line-height: 3rem;
            max-width: 19.75rem;
        }

        @include media-breakpoint-up(md) {
            font-size: 3.5rem;
            line-height: 3.75rem;
            max-width: 26.25rem;
        }

        @include media-breakpoint-up(lg) {
            font-size: $heading-hero;
            line-height: $line-height-hero;
            max-width: 36rem;
        }

        &--bold, strong {
            @include font-primary-bold();
        }

    &--landing-page {
      color: $white;
      width: 100%;

            @include media-breakpoint-up(sm) {
                width: 50%;
                max-width: 21.875rem;
            }

            @include media-breakpoint-up(md) {
                width: 50%;
                max-width: 26.8rem;
            }

            @include media-breakpoint-up(lg) {
                width: 50%;
            }

            @include media-breakpoint-up(xl) {
                width: 32.5rem;
            }
        }
    }

    &__mobile-header-container {
        display: flex;

        @include media-breakpoint-up(sm) {
            display: none;
        }
    }
}

@include media-breakpoint-up(sm) {
    .hero {
        &__header {
            margin-top: 0;
        }
    }
}