﻿// TODO - replace with BF.com styles!!! Directly taken from Finpro.Web solution.
$color-brand-black: #191919;
$font-size-base: 1.6rem;
$font-size-small: 1.4rem;
$font-size-site-locator: 1.8rem;

.data-center-map-block {
    .mapLabels {
        color: $primary;
        text-transform: uppercase;
    }

    .preferences {
        color: $primary;
    }

    #ctrl #hide, #show {
        text-transform: uppercase;
    }

    .datacenter-map#main-datacenter-map {
        width: 100%;
        height: 100%;
        position: relative;
        margin: 0 auto;
        z-index: 1;
        min-height: 775px;

        a {
            color: $primary;
            font-weight: bold;
        }

        a:visited {
            color: $primary;
        }

        a:hover {
            text-decoration: none;
        }

        #other-iopps a:hover, #other-colocs a:hover {
            text-decoration: underline;
        }

        .clearfix:after {
            content: "";
            display: block;
            clear: both;
        }

        .labels {
            font-size: $font-size-small;
            font-weight: 700;
            color: white;
            background-color: transparent;
            text-align: center;
            width: 30px;
            white-space: nowrap;
        }

        iframe {
            width: 100%;
            height: 800px;
            border: none;
        }

        #map, #map2 {
            width: 100%;
            height: 750px;
            position: relative;
            display: block;
            z-index: 2;
        }

        .custom-controls-wrapper {
            margin-left: 92%;
            margin-bottom: 10px;
        }

        .custom-zoom-out {
            text-align: center;
            width: 33px;
            height: 33px;
            background-image: url(../../icons/minus.png);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            display: block;
            border: none;
            padding: 0;
            margin: 0;
            cursor: pointer;
        }

        .custom-zoom-in {
            text-align: center;
            width: 33px;
            height: 33px;
            background-image: url(../../icons/plus.png);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            display: block;
            border: none;
            padding: 0;
            margin: 0;
            cursor: pointer;
            margin-bottom: 2px;
        }

        #filters {
            width: 350px;
            background-color: white;
            position: absolute;
            left: 2px;
            top: 0;
            z-index: 5;
            padding: 0 20px 0 20px;
            height: 100%;

            hr {
                color: black;
            }
        }

        .height-auto {
            height: auto !important;
        }

        #filters .filter-label {
            color: $primary;
            padding: 20px 0 5px 0;
            display: block;
        }

        #filters .filter-stars {
            color: $primary;
            padding-top: 30px;
            padding-bottom: 30px;
        }

        #filters input[type=range] {
            -webkit-appearance: none;
            appearance: none;
            margin: 10px 0;
            width: 100%;
        }

        #filters input[type=range]:focus {
            outline: none;
        }

        #filters input[type=range]::-webkit-slider-runnable-track {
            width: 100%;
            height: 2px;
            cursor: pointer;
            animate: 0.2s;
            box-shadow: 0px 0px 0px #000000;
            background: $primary;
            border-radius: 1px;
            border: 0px solid #000000;
        }

        #filters input[type=range]::-webkit-slider-thumb {
            box-shadow: 0px 0px 0px #000000;
            border: 1px solid $primary;
            height: 18px;
            width: 18px;
            border-radius: 25px;
            background: $primary;
            cursor: pointer;
            -webkit-appearance: none;
            margin-top: -8.5px;
        }

        #filters input[type=range]:focus::-webkit-slider-runnable-track {
            background: $primary;
        }

        #filters input[type=range]::-moz-range-track {
            width: 100%;
            height: 2px;
            cursor: pointer;
            animate: 0.2s;
            box-shadow: 0px 0px 0px #000000;
            background: $primary;
            border-radius: 1px;
            border: 0px solid #000000;
        }

        #filters input[type=range]::-moz-range-thumb {
            box-shadow: 0px 0px 0px #000000;
            border: 1px solid $primary;
            height: 18px;
            width: 18px;
            border-radius: 25px;
            background: $primary;
            cursor: pointer;
        }

        #filters input[type=range]::-ms-track {
            width: 100%;
            height: 2px;
            cursor: pointer;
            animate: 0.2s;
            background: transparent;
            border-color: transparent;
            color: transparent;
        }

        #filters input[type=range]::-ms-fill-lower {
            background: $primary;
            border: 0px solid #000000;
            border-radius: 2px;
            box-shadow: 0px 0px 0px #000000;
        }

        #filters input[type=range]::-ms-fill-upper {
            background: $primary;
            border: 0px solid #000000;
            border-radius: 2px;
            box-shadow: 0px 0px 0px #000000;
        }

        #filters input[type=range]::-ms-thumb {
            box-shadow: 0px 0px 0px #000000;
            border: 1px solid $primary;
            height: 18px;
            width: 18px;
            border-radius: 25px;
            background: $primary;
            cursor: pointer;
        }

        #filters input[type=range]:focus::-ms-fill-lower {
            background: $primary;
        }

        #filters input[type=range]:focus::-ms-fill-upper {
            background: $primary;
        }

        #filters .maxmin {
            height: 5px;
            margin-bottom: 10px;
            color: $primary;
        }

        #filters .maxmin .min {
            float: left;
        }

        #filters .maxmin .max {
            float: right;
        }

        #filters .map-filter-container .last-of-type {
            margin-bottom: 20px;
        }

        .map-list-buttons {
            position: absolute;
            left: 360px;
            top: 0;
            z-index: 5;
            width: 200px;
        }

        .preferencesText {
            color: $primary;
            text-transform: uppercase;
            margin-left: 1rem;
            font-weight: 700;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        #hideArrowLeft, #hideArrowRight {
            width: 15px;
            height: 27px;
        }

        .button {
            border: 3px solid $primary;
            padding: 10px;
            cursor: pointer;
            width: 95px;
            text-align: center;
            color: $primary;
        }

        .button-active {
            background-color: $primary !important;
            color: #fff !important;
        }

        .accordion-button {
            background-color: white;
            color: $primary;
            border: 0;
            width: 100%;
            padding: 0;
            box-shadow: none;
            :focus {
                @include focus-styles();
            }
        }

        .accordion-item {
            border: none;
        }

        .accordion-body {
            padding: 0 !important;
        }

        #mapBtn {
            background-color: #fff;
            float: left;
            color: $primary;
        }

        #listBtn {
            background-color: #fff;
            float: right;
            color: $primary;
        }

        #ctrl .map-filter-container {
            padding-left: 27px;
            padding-right: 37px;
            display: block;
            margin: auto;
        }

        #ctrl .filter-checkbox-row {
            padding: 0.5rem;
        }


        .filter-checkbox {
            display: inline;
        }

        #ctrl .filter-checkbox-row .filter-label {
            display: inline;
            text-transform: uppercase;
            font-size: $font-size-small;
            margin-left: 11px;
        }


        .filter-checkbox-row-co-locations {
            border-left-color: #967dff;
            border-left-style: solid;
            border-left-width: 10px;
        }

        .filter-checkbox-row-co-locations-list {
            border-left-color: #967dff;
            border-left-style: solid;
            border-left-width: 10px;
            padding-left: 20px;
            padding-bottom: 10px
        }

        .filter-checkbox-row-investment-opportunities {
            border-left-color: $primary;
            border-left-style: solid;
            border-left-width: 10px;
            padding: 0.5rem;
            padding-right: 0;
        }

        .filter-checkbox-row-investment-opportunities-list {
            border-left-color: $primary;
            border-left-style: solid;
            border-left-width: 10px;
            padding: 0.5rem;
            padding-right: 0;
            padding-left: 20px;
            padding-bottom: 10px
        }

        .border-investment-list {
            border-bottom: 1px solid $primary;
        }

        .pointer-icon-list {
            width: 20px;
            height: 20px;
        }

        .fontSize14 {
            font-size: 14px;
        }

        #resetHide {
            height: 35px;
            line-height: 35px;
            display: flex;
        }

        #reset-all {
            padding: 0;
            border: none;
            cursor: pointer;
            background-color: transparent;
            color: $primary;
            text-transform: none;
            display: flex;
            flex-direction: column;
            margin-left: auto;
        }

        #hide {
            float: left;
            cursor: pointer;
            padding: 0;
            background-color: transparent;
            border: none;
            color: $primary;
            font-weight: 700;
        }

        #show {
            position: absolute;
            left: 2px;
            top: 24px;
            z-index: 4;
            background-color: white;
            height: 58px;
            line-height: 58px;
        }

        #show a {
            font-size: $font-size-small;
            padding: 0px 15px;
            display: inline-block;
        }

        #listContainer {
            display: none;
            height: auto;
            z-index: 5;
            position: absolute;
            left: 350px;
            right: 0px;
            top: 90px;
            max-height: 665px;
            overflow-y: auto;
        }

        #listContainer span {
            color: $primary;
        }

        #listContainer #list {
            list-style-type: none;
            width: 100%;
        }

        #listContainer #list a {
            // text-transform: uppercase;
            font-size: 1rem;
            text-decoration: none;
            color: $primary;
        }


        #firstHeading a {
            text-transform: uppercase;
            text-decoration: none;
            font-size: $font-size-site-locator;
        }

        .noMargins {
            margin: 0;
        }


        #bodyContent p {
            font-size: $font-size-small;
        }

        .gm-style-iw #content {
            text-align: center;
        }

        .gm-style-iw h3 {
            text-transform: none;
        }

        #layer-menu {
            position: absolute;
            right: 0;
            top: 13%;
            width: 200px;
            font-size: $font-size-small;
            color: $primary;
            text-transform: uppercase;
            background-color: white;
            z-index: 5;
        }

        #layer-menu h2 {
            text-align: center;
        }

        #layer-menu h2 a {
            font-size: $font-size-small;
            color: $primary;
        }

        #energy-menu-container {
            line-height: 40px;
            position: relative;

            h2 {
                font-size: $font-size-base;
            }
        }

        #energy {
            padding-top: 0.5rem;
            padding-left: 1rem;
            @include font-primary-bold();
        }

        #connectivity-menu-container {
            line-height: 40px;
            border-bottom: solid 1px $primary;
            position: relative;
        }

        #connectivity-menu-container:before {
            content: "";
            background: url(../../icons/icon-connectivity.svg) no-repeat;
            position: absolute;
            height: 30px;
            width: 30px;
            top: 6px;
            left: 15px;
        }

        #weather {
            padding-left: 1rem;
            @include font-primary-bold();
        }

        .layer-selection-container {
            background: white;
            color: $primary;
            text-transform: capitalize;
            text-align: left;
        }

        .last-layer-selection-container {
            border-bottom: none;
        }

        .layer-checkbox {
            margin-top: 5px;
            margin-right: 16px;
            float: left;
        }

        .layer-label {
            text-transform: capitalize;
        }

        .temperature-legend-wrapper {
            margin-top: 20px;
            height: 150px;
            position: relative;
        }

        .temperature-legend-left {
            width: 90px;
            position: absolute;
            left: 0;
            top: 0;
        }

        .temperature-legend-right {
            width: 90px;
            position: absolute;
            right: 0;
            top: 0;
        }

        .temperature-legend {
            display: inline-block;
            margin: 5px;
        }

        .legend-color {
            display: inline-flex;
            height: 14px;
            width: 14px;
            margin-right: 3px;
        }

        .color-1 {
            background-color: #33bcd3;
        }

        .color-2 {
            background-color: #6ddbe5;
        }

        .color-3 {
            background-color: #a6f3f7;
        }

        .color-4 {
            background-color: #d5ffff;
        }

        .color-5 {
            background-color: #00B68D;
        }

        .color-6 {
            background-color: #00D799;
        }

        .color-7 {
            background-color: #61EFBE;
        }

        .color-8 {
            background-color: #BFFFD3;
        }

        .color-9 {
            background-color: #E8FCD1;
        }

        .color-10 {
            background-color: #E9FF84;
        }

        .legend-text {
            display: inline-block;
        }
    }

    .accordion__toggler_arrow {
        position: relative;

        &::after {
            content: '';
            width: 20px;
            display: inline-block;
            background: url(../../img/form/arrow-down.svg) center center no-repeat;
            right: 0px;
            top: 0px;
            height: 100%;
            vertical-align: middle;
            position: absolute;
            transition: transform .2s ease-in-out;
            cursor: pointer;
        }

        &[aria-expanded="true"] {
            &::after {
                transform: rotate(-180deg);
            }
        }
    }

    .filter-stars ul {
        list-style-type: none;
    }

    .filter-stars li {
        color: white;
        text-align: center;
        padding-top: 10px;
    }

    .star, .star-on {
        cursor: pointer;
        width: 40px;
        height: 40px;
        margin-right: 0.4rem;
        display: inline-block;
        background: url(../../img/form/star.svg) center center no-repeat;
    }

    .star-off {
        opacity: 0.3;
    }

    .ui-state-default {
        border: 1px solid $primary !important;
        background: $primary !important;
    }

    .slider-current-max, .slider-current-min {
        color: $primary;
        @include font-primary-bold();
    }

    @mixin track() {
        background: none; /* get rid of Firefox track background */
        height: 100%;
        width: 100%;
    }

    @mixin thumb() {
        background: currentcolor;
        border: none; /* get rid of Firefox thumb border */
        border-radius: 0; /* get rid of Firefox corner rounding */
        pointer-events: auto;
        width: 1em;
        height: 1em;
    }

    .map-range-input {
        display: grid;
        grid-column: 1;
        grid-row: 2;

        &::-webkit-slider-runnable-track,
        &::-webkit-slider-thumb, & {
            -webkit-appearance: none;
        }

        background: none; /* get rid of white Chrome background */
        color: #000;
        font: inherit; /* fix too small font-size in both Chrome & Firefox */
        margin: 0;
        pointer-events: none;

        &::-webkit-slider-runnable-track {
            @include track;
        }

        &::-moz-range-track {
            @include track;
        }

        &::-webkit-slider-thumb {
            @include thumb;
        }

        &::-moz-range-thumb {
            @include thumb;
        }
    }

    .slider-current-min {
        display: flex;
        width: 100%;
        justify-content: flex-start;
    }

    .slider-current-max {
        display: flex;
        width: 100%;
        justify-content: flex-end;
    }

    .ui-widget-header {
        background: $primary;
    }

    .ui-slider-handler {
        width: 5px !important;
    }

    .ui-widget.ui-widget-content {
        border: none;
        background-color: #d2d2d2;
        height: 2px;
    }

    .ui-slider-horizontal .ui-slider-handle {
        top: -0.5em;
    }

    #popup-image img {
        width: 200px;
    }

    @include media-breakpoint-down(sm) {
        .map-list-buttons, #filters, #map, #listContainer {
            position: relative !important;
        }

        #map {
            top: 0;
        }

        #filters {
            width: 100% !important;
        }

        #listContainer {
            left: 0 !important;
            width: 100%;
        }

        .map-list-buttons {
            top: 55px !important;
            left: 5px !important;
        }

        .mobile-investment-list {
            display: block;
        }

        .mobile-colocations-list {
            display: inline-block;
            margin-left: 30px;
        }

        .form-element__checkbox {
            padding-top: 0;
        }
    }
}