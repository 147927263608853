.content {
    // Fixing the bg image overflow and centering the background image
    &__bg-image-container {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
    }

    &__bg-image {
        object-fit: cover;
        display: block;
        width: 100%;
        aspect-ratio: 1 / 1;
        max-height: 590px;

        @include media-breakpoint-down(md) {
            max-height: 380px;
            padding-bottom: $gap-large-px;
        }
    }
}
